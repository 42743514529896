import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import BGImg from "../../../assets/img/bg/bdrc-bg.jpg";
import { useDispatch, useSelector } from 'react-redux';
import CottageAction from "../../../store/actions/cottageAction";
import Loader from "../../loader";

const Cottage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [exactCottage, setExactCottage] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const cottagesData = useSelector((state) => state.cottagesData);

    useEffect(() => {
        // Load cottages data
        CottageAction.loadCottages(dispatch);
        
        // Set a timeout to ensure the loader is shown for at least 2 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 2 seconds

        return () => clearTimeout(timer); // Clear timeout if the component is unmounted before 2 seconds
    }, [dispatch]);

    useEffect(() => {
        if (cottagesData.cottagesList.data) {
            const foundCottage = cottagesData.cottagesList.data.data.find((cottage) => cottage._id === id);
            setExactCottage(foundCottage);
        }
    }, [cottagesData, id]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []); // Empty dependency array ensures this runs only once

    if (loading || !exactCottage) {
        return <Loader/>; // Show loader until the loading state is set to false
    }

    return (
        <>
            <main>
                <section id='head' className="breadcrumb-area d-flex align-items-center" style={{ background: `url(${exactCottage.cottageBanner || 'https://basilresort.s3.ap-south-1.amazonaws.com/CottageSubImages/cottageSubImages/Mud%20house-634fe97b063b600fedf4e1ae/1'})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-center">
                                    <div className="breadcrumb-title">
                                        <h2 style={{ fontSize: '3rem',
                fontWeight: 'bold', }}>{exactCottage.title1}</h2>
                                        <div className="breadcrumb-wrap">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">{exactCottage.title1} Details</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="about-area5 about-p p-relative">
                    <div className="container pt-120 pb-40">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-4 order-2">
                                <aside className="sidebar services-sidebar">
                                    <div className="sidebar-widget categories">
                                        <div className="widget-content">
                                            <h2 className="widget-title">Book A Room</h2>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <label className='mb-0'><i className="fal fa-phone"></i> Call us</label>
                                                    <a href='tel:9061831733' style={{ cursor: "pointer" }}><h4>+91-9778434442</h4></a>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <label className='mb-0'><i className="fal fa-envelope"></i> Mail us</label>
                                                    <a style={{ cursor: "pointer" }} href='mailto:info@sandalmistresort.com?subject=Cottage Price Enquiry&body=Hello!'><h4>info@sandalmistresort.com</h4></a>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <label className='mb-0'><i style={{ color: 'green' }} className="fab fa-whatsapp"></i> Text us on whatsapp</label>
                                                    <a style={{ cursor: "pointer" }} href='https://api.whatsapp.com/send?phone=9061831733'><h4>+91-9778434442</h4></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                                        <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                                        <Link to="" title="Call now"></Link>
                                    </div> */}
                                </aside>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 order-1">
                                <div className="service-detail">
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image"><img src={exactCottage.cottageVertical || "https://basilresort.s3.ap-south-1.amazonaws.com/CottageSubImages/cottageSubImages/Premium-634fe97b063b600fedf4e1ae/0"} alt="" /></figure>
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image"><img src={exactCottage.cottageImages[0]} alt="" /></figure>
                                                <figure className="image"><img src={exactCottage.cottageImages[1]} alt="" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
    <div className="row align-items-center mb-50">
        <div className="col-lg-6 col-md-6">
            {exactCottage.price > 0 && (
                <div className="price">
                    <span>₹{exactCottage.price}/Night</span>
                </div>
            )}
        </div>
        <div className="col-lg-6 text-right"></div>
    </div>
    <h4>{exactCottage.title1}</h4>
    <p>{exactCottage.paragraph1 ?? "A cottage, during England's feudal period, was the holding by a cottager..."}</p>
    <h4>{exactCottage.title2}</h4>
    <p>{exactCottage.paragraph2}</p>
    <h3>Room Feature.</h3>
    <ul className="room-features d-flex align-items-center">
        {exactCottage.numberOfRooms && <li><i className="fal fa-person-booth"></i> Rooms: {exactCottage.numberOfRooms}</li>}
        {exactCottage.numberOfBeds && <li><i className="fal fa-bed"></i> No. beds: {exactCottage.numberOfBeds}</li>}
        {exactCottage.numberOfBathrooms && <li><i className="fal fa-sink"></i> Bathrooms: {exactCottage.numberOfBathrooms}</li>}
        {exactCottage.balcony && <li><i className="fal fa-torii-gate"></i> Balcony: {exactCottage.numberOfBalcony}</li>}
        {exactCottage.area && <li><i className="fal fa-square"></i> Size: {exactCottage.area}</li>}
        {exactCottage.television && <li><i className="fal fa-tv-retro"></i> TV</li>}
        {exactCottage.wifi && <li><i className="fal fa-wifi"></i> Wifi</li>}
        {exactCottage.acAvailable && <li><i className="fal fa-air-conditioner"></i> Air Condition</li>}
        {exactCottage.heater && <li><i className="fal fa-dumpster-fire"></i> Heater</li>}
        {exactCottage.landPhone && <li><i className="fal fa-phone-rotary"></i> Phone</li>}
        {exactCottage.cableTv && <li><i className="fal fa-tv"></i> Cable TV</li>}
    </ul>
</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                    {/* cottage gallery images */}

                    <div className="about-area5 about-p p-relative">
                        <div className="container pt-120 pb-40">
                            <div className="row">
                               
                                <div className="col-lg-12 col-md-12 col-sm-12 order-1">
                                    <div className="service-detail">
                                        <div className="two-column">
                                            <div className='d-flex justify-content-center'>
                                                <h2 style={{"color":"#85ab00"}}>Cottage Images</h2>
                                            </div>
                                            <div className="row">
                                            {exactCottage.cottageImages.map(image => {
                                               
                                                return( <div className="image-column col-xl-4 col-lg-4 col-md-4">
                                                 <figure className="image"><img src={image} alt="" height={241} width={409} /></figure>
                                                 </div>)
                                            })}


                                              
                                            </div>
                                        </div>
                                       






                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
            </main>
        </>
    );
};

export default Cottage;
