import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../services/config";

const FacilityDetails = () => {
  const { id } = useParams();
  const [facility, setFacility] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Fetching facility with id:", id);

    axios
      .get(`${config.baseUrl}getFacility/${id}`)
      .then((response) => {
        console.log("Full response:", response);
        console.log("Response data:", response.data);
        if (response.data.data) {
          setFacility(response.data.data);
        } else {
          setError("No facility data found.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching facility details:", error);
        setError("Failed to fetch facility details.");
        setLoading(false);
      });
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!facility) return <p>No facility found.</p>;

  return (
    <div className="container mt-5">
      <h1>hello</h1>
      <h1>{facility.title}</h1>
      <img
        src={facility.menuImage || "https://via.placeholder.com/600x400"}
        alt={facility.title}
        style={{ width: "100%", height: "auto" }}
      />
      <p>{facility.description}</p>
    </div>
  );
};

export default FacilityDetails;
