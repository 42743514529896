import React from "react";

const TermsAndConditions = () => {
  const styles = {
    container: {
      padding: "60px",
      maxWidth: "1300px",
      margin: "auto",
      fontFamily: "Arial, sans-serif",
      textAlign: "left",
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    },
    header: {
      textAlign: "center",
      marginBottom: "50px",
      color: "#2c3e50",
    },
    headerTitle: {
      fontSize: "3em",
      fontWeight: "700",
      color: "#000000", // Black color
      letterSpacing: "1px",
    },
    section: {
      marginBottom: "40px",
    },
    sectionTitle: {
      fontSize: "2em",
      marginBottom: "20px",
      textAlign: "left",
      fontWeight: "600",
      color: "#000000", // Black color
    },
    sectionText: {
      fontSize: "1.15em",
      lineHeight: "1.75",
      color: "#000000", // Black color
    },
    contactLink: {
      color: "#000000", // Black color
      fontWeight: "600",
      textDecoration: "none",
      paddingLeft: "5px",
    },
    contactLinkHover: {
      textDecoration: "underline",
    },
    footer: {
      textAlign: "center",
      padding: "20px 0",
      borderTop: "1px solid #ddd",
      marginTop: "40px",
      color: "#555",
    },
  };

  return (
    <div className="container-fluid mt-5 pt-5" style={styles.container}>
      <header style={styles.header}>
        <h2 className="pt-5 pb-4">Terms And Conditions</h2>
      </header>
      <main>
        <section style={styles.section}>
          <h4>Introduction</h4>
          <p1>
            Welcome to <strong>SandalMist</strong>. These Terms and Conditions
            govern your use of our website and the services we offer at our
            resort. By accessing or using our website, you agree to comply with
            and be bound by these terms. Please read them carefully before
            making a reservation.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>1. Definitions</h4>
          <p1>
            "Resort" refers to <strong>SandalMist</strong>, located at Munnad,
            Kasargod, Kerala.
            <br />
            "Guest" refers to any person who makes a reservation or stays at the
            Resort.
            <br />
            "Website" refers to{" "}
            <a href="https://sandalmistresort.com/" style={styles.contactLink}>
              https://sandalmistresort.com/
            </a>
          </p1>
        </section>

        <section style={styles.section}>
          <h4>2. Booking and Reservations</h4>
          <p1>
            <strong>Booking Confirmation:</strong> Reservations are confirmed
            only upon receipt of full or partial payment as per the resort's
            policy. A confirmation email will be sent to the guest.
            <br />
            <strong>Cancellation Policy:</strong> Cancellations must be made 3
            days prior to the check-in date to receive a full refund.
            Cancellations made after this period may incur charges as per the
            resort's policy.
            <br />
            <strong>No-Show Policy:</strong> In case of a no-show, the total
            booking amount will be charged.
            <br />
            <strong>Refund policy:</strong> At SandalMist, we are committed to
            providing exceptional service and flexibility to our guests. All
            approved refunds will be processed within 7-10 business days and
            returned to the original payment method used. Processing time may
            vary depending on your bank or credit card provider. Bookings made
            under non-refundable promotions or special offers are not eligible
            for refunds or date changes. If you have any questions or need
            assistance regarding cancellation or refunds, please contact our
            resort over phone or email.
            <br />
            <strong>Availability:</strong> All bookings are subject to
            availability and confirmation. A valid credit card is required to
            secure your reservation.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>3. Check-in and Check-out</h4>
          <p1>
            <strong>Check-in Time:</strong> 2:00 PM IST
            <br />
            <strong>Check-out Time:</strong> 11:00 AM IST
            <br />
            <strong>Early Check-in/Late Check-out:</strong> Subject to
            availability and may incur additional charges.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>4. Payment Terms</h4>
          <p1>
            <strong>Payment Methods:</strong> We accept credit/debit cards, UPI,
            online banking, Cash, etc.
            <br />
            <strong>Taxes:</strong> All rates are inclusive/exclusive of
            applicable taxes as per the government regulations.
            <br />
            <strong>Security Deposit:</strong> A refundable security deposit may
            be required at check-in to cover any incidental charges.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>5. Guest Responsibilities</h4>
          <p1>
            <strong>Conduct:</strong> Guests are expected to behave respectfully
            and not disturb other guests. Any form of misconduct may result in
            immediate eviction without refund.
            <br />
            <strong>Damage to Property:</strong> Guests are liable for any
            damage caused to the resort's property during their stay. The cost
            of repair/replacement will be charged to the guest.
            <br />
            <strong>Compliance with Laws:</strong> Guests must comply with all
            applicable laws during their stay.
          </p1>
        </section>
        <section style={styles.section}>
          <h4>6. Parking Policy</h4>
          <p1>
            At <strong>SandalMist</strong>, we aim to provide a smooth and
            hassle-free parking experience for all our guests. Please read
            through our parking policy to ensure you are familiar with our terms
            and conditions regarding parking on the premises.
            <br />
            <strong>Complimentary Parking:</strong> We offer complimentary
            parking for all registered guests staying at the resort. Each room
            is entitled to one parking space. Additional parking spaces are
            subject to availability and may incur additional charges.Day
            visitors must register their vehicles at the front desk.
            <br />
            <strong>Valet Parking:</strong> Valet parking is available upon
            request. Our valet service is complimentary for all guests staying
            at the resort.
            <br />
            <strong>Electric Vehicle (EV) Charging Stations:</strong> We do not
            offer EV charging stations at this time. Please inquire at the front
            desk for information on other feasible options.
            <br />
            <strong>Oversized Vehicles:</strong> If you are arriving in an
            oversized vehicle (such as an RV, trailer, or bus), please contact
            us in advance to arrange appropriate parking. Due to limited space,
            availability for oversized vehicles is not guaranteed and may incur
            additional charges.
            <br />
            <strong>Parking for Persons with Disabilities:</strong> Accessible
            parking spaces are available for guests with disabilities. These
            spots are conveniently located close to the resort entrance.
            Vehicles parked in these spaces must display a valid disabled
            parking permit.
            <br />
            <strong>Parking Security:</strong> Our parking area is monitored
            24/7 by surveillance cameras, and security personnel regularly
            patrol the lot. However, the resort is not liable for any loss,
            theft, or damage to vehicles or belongings left inside the vehicles.
            Guests are advised to take precautions and lock their vehicles at
            all times.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>7. Children Policy</h4>
          <p1>
            <strong>Children Stay Free:</strong> Children under the age of 10
            stay free when sharing a room with parents or guardians, using
            existing bedding. For additional bedding, charges may apply (see
            section below on extra bedding).
            <br />
            <strong>Child Age Definition:</strong> For the purposes of our
            policy, children are considered to be under 10 years old. Children
            aged 11 and older are considered adults for pricing purposes.
            <br />
            <strong>Cribs and Infant Facilities:</strong> We provide cribs for
            infants at no additional cost based on availability.. Please request
            a crib at the time of booking to ensure availability.
            <br />
            <strong>Children’s Amenities:</strong> Our resort offers a variety
            of family-friendly amenities, including a children’s pool, play
            area, and kid-friendly menu options at our restaurants.Childerns
            must be accompanied by an adult while using the resort facilities,
            especially the swimming pool and kids park.
          </p1>
        </section>
        <section style={styles.section}>
          <h4>8. Extra Guest Policy</h4>
          <p1>
            <strong>Maximum Occupancy:</strong> Each room at SandalMist has a
            maximum occupancy limit, which varies based on the room type. Please
            refer to your room type’s specifications for the maximum number of
            guests allowed.
            <br />
            <strong>Extra Guests and Charges:</strong> For each additional guest
            over the standard room occupancy, there is a charge per night. Extra
            guests must be registered at the time of check-in to confirm about
            extra fee and to avoid additional penalties
            
          </p1>
        </section>
        <section style={styles.section}>
          <h4>9. Resort Rights</h4>
          <p1>
            <strong>Room Allocation:</strong> The resort reserves the right to
            assign rooms based on availability.
            <br />
            <strong>Policy Changes:</strong> The resort reserves the right to
            amend these terms and conditions at any time without prior notice.
            <br />
            <strong>Force Majeure:</strong> The resort is not liable for any
            failure to perform its obligations due to events beyond its control,
            such as natural disasters, pandemics, or governmental regulations.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>10. Liability</h4>
          <p1>
            <strong>Personal Belongings:</strong> The resort is not responsible
            for the loss or damage of any personal belongings. Guests are
            advised to use in-room safes for valuables.
            <br />
            <strong>Injury or Loss:</strong> The resort is not liable for any
            injury, loss, or damage incurred by guests during their stay.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>11. Website Use</h4>
          <p1>
            <strong>Content:</strong> All content on the website, including
            text, images, and logos, is the property of the resort and may not
            be used without permission.
            <br />
            <strong>Third-Party Links:</strong> The website may contain links to
            third-party websites. The resort is not responsible for the content
            or practices of these websites.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>12. Governing Law</h4>
          <p1>
            These terms and conditions are governed by the laws of India and are
            subject to the jurisdiction of the courts in Kasargod, Kerala.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>13. Changes to Terms</h4>
          <p1>
            We reserve the right to modify these terms and conditions at any
            time. Any changes will be posted on this page, and continued use of
            our services constitutes acceptance of the updated terms.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>14. Privacy Policy</h4>
          <p1>
            Your privacy is important to us. We collect and use personal
            information in accordance with our privacy policy. By using our
            services, you consent to the collection and use of your personal
            information as described in our privacy policy.
          </p1>
        </section>

        <section style={styles.section}>
          <h4>15. Contact Information</h4>
          <p1>
            For any questions or concerns regarding these terms, please contact
            us at:
            <br />
            <strong>SandalMist Resort</strong>
            <br />
            Munnad PO
            <br />
            Kasargod, Kerala 671541
            <br />
            Ph: +91 9778434442
            <br />
            <a
              href="mailto:info@sandalmistresort.com"
              style={styles.contactLink}
            >
              info@sandalmistresort.com
            </a>
          </p1>
        </section>
      </main>
    </div>
  );
};

export default TermsAndConditions;
