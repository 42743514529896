import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BGImg from "../../assets/img/slider/slider_bg.png";
import BGImg1 from "../../assets/img/slider/slider_bg_01.png";
import BGTest from "../../assets/img/testimonial/leaves.jpg";
import BGVideo from "../../assets/img/bg/video-bg.png";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import About from "../../assets/img/bg/an-img-02.png";
import About1 from "../../assets/img/resort/resortimage-1.jpg";
import About2 from "../../assets/img/resort/resortimage-2.jpg";
import About3 from "../../assets/img/features/signature.png";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png";
import Hotel2 from "../../assets/img/icon/fe-icon04.png";
import Hotel3 from "../../assets/img/icon/fe-icon05.png";
import Hotel4 from "../../assets/img/icon/fe-icon06.png";
import Hotel5 from "../../assets/img/icon/fe-icon07.png";
import Hotel6 from "../../assets/img/icon/fe-icon08.png";
import service1 from "../../assets/img/gallery/room-img01.png";
import service2 from "../../assets/img/gallery/room-img02.png";
import service3 from "../../assets/img/gallery/room-img03.png";
import service4 from "../../assets/img/gallery/room-img04.png";
import icon1 from "../../assets/img/icon/sve-icon1.png";
import icon2 from "../../assets/img/icon/sve-icon2.png";
import icon3 from "../../assets/img/icon/sve-icon3.png";
import icon4 from "../../assets/img/icon/sve-icon4.png";
import icon5 from "../../assets/img/icon/sve-icon5.png";
import icon6 from "../../assets/img/icon/sve-icon6.png";
import Resort from "../../assets/img/bg/an-img-02.png";
import Resort1 from "../../assets/img/features/feature.png";
import pricing1 from "../../assets/img/bg/an-img-02.png";
import avatar1 from "../../assets/img/testimonial/testi_avatar.png";
import avatar2 from "../../assets/img/testimonial/testi_avatar_02.png";
import avatar3 from "../../assets/img/testimonial/testi_avatar_03.png";
import Aicon from "../../assets/img/testimonial/qt-icon.png";
import Aicon1 from "../../assets/img/testimonial/review-icon.png";
import Booking from "../../assets/img/bg/booking-img.png";
import Play from "../../assets/img/bg/play-button.png";
import Blog from "../../assets/img/bg/an-img-06.png";
import Blog1 from "../../assets/img/blog/inner_b1.jpg";
import Blog2 from "../../assets/img/blog/inner_b2.jpg";
import Blog3 from "../../assets/img/blog/inner_b3.jpg";
import Brand1 from "../../assets/img/brand/b-logo1.png";
import Brand2 from "../../assets/img/brand/b-logo2.png";
import Brand3 from "../../assets/img/brand/b-logo3.png";
import Brand4 from "../../assets/img/brand/b-logo4.png";
import Brand5 from "../../assets/img/brand/b-logo5.png";
import axios from "axios";
import config from "../../services/config";
import sandalmist from "../../assets/img/logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import ActivitiesAction from "../../store/actions/ActivitiesAction";
import ActivityDetails from "../Facilities/ActivityDetails/ActivityDetails";
import keralaImg from "../../assets/img/bg/kerala_ban.jpg";
import Modal from "react-modal";

// var ActivitiesList = [];
// var cottagesList = [];
// var testimonialsList = [];
// var blogsList = [];


const Main = () => {
  const [yShow, setyShow] = useState();
  const [hShow, sethShow] = useState();
  const [animation, setanimation] = useState();

  const [toggler, setToggler] = useState(false);
  const [activeImage, setActiveImage] = useState(1);

  const [ActivitiesList, setActivitiesList] = useState([]);
  const [cottagesList, setCottagesList] = useState([]);
  const [blogsList, setBlogsList] = useState([]);
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [youtubeVideo, setYoutube] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  console.log("youtubeVideo: ", youtubeVideo);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllBannerImages();
    getAllActivities();
    getAllCottages();
    getAllTestimonialAPI();
    getRecentBlogsAPI();
  }, []);
  const [bannerImages, setbannerImages] = useState([]);

  function getAllBannerImages() {
    axios({
      url: config.baseUrl + "getAllBannerImages",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.status == 200) {
          setbannerImages(res.data.data.filter((e) => e.isActive == true));
        } else if (res.status == 201) {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err.toString());
      });
  }

  function getAllActivities() {
    axios({
      url: config.baseUrl + "getAllActivities",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        // if (res.status == 200) { ActivitiesList = res.data.data }
        if (res.status == 200) {
          setActivitiesList(res.data.data);
        } else if (res.status == 201) {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err.toString());
      });
  }

  function getAllCottages() {
    axios({
      url: config.baseUrl + "getAllCottages",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.status == 200) {
          // cottagesList = res.data.data;
          setCottagesList(res.data.data);
          // console.log('cottagesList: ', cottagesList);
        } else if (res.status == 201) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.toString());
      });
  }

  function getAllTestimonialAPI() {
    axios({
      url: config.baseUrl + "getAllTestimonials",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.status == 200) {
          // testimonialsList = res.data.data;
          setTestimonialsList(res.data.data);
          // console.log('testimonialsList: ', testimonialsList);
        } else if (res.status == 201) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.toString());
      });
  }

  function getRecentBlogsAPI() {
    axios({
      url: config.baseUrl + "getRecentBlogs",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.status == 200) {
          // blogsList = res.data.data;
          // console.log('blogsList: ', blogsList);
          setBlogsList(res.data.data);
        } else if (res.status == 201) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.toString());
      });
  }

  function monthDateYear(dates) {
    const date = new Date(dates);
    const monthName =
      date.toString().split(" ")[2] +
      ", " +
      date.toString().split(" ")[1] +
      " " +
      date.toString().split(" ")[3];
    return monthName;
  }

  const [images, setImages] = useState([
    "assets/img/gallery/room-img01.png",
    "assets/img/gallery/room-img02.png",
    "assets/img/gallery/room-img03.png",
    "assets/img/gallery/room-img04.png",
    "assets/img/gallery/room-img05.png",
    "assets/img/gallery/room-img06.png",
  ]);

  const home = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const customStyles = {
    content: {
      top: "40%",
      left: "20%",
      right: "20%",
      bottom: "auto",
    },
  };

  const service = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonial = {
    initialIndex: 2,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const brand = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <main>
        <section id="home" className="slider-area fix p-relative">
          <Slider className="slider-active" {...home}>
            {bannerImages.map((e, i) => {
              //   console.log(e);
              return (
                <>
                  {/* <div className="col-xl-2"> */}
                  <div
  className="single-slider slider-bg d-flex align-items-center"
  style={{
    backgroundImage: `url(${e.bannerImage})`,
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden", // Ensure the fade effect stays within the bounds of the image
  }}
>
  {/* White fade effect on the top only */}
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "15%", // Reduced height for a smaller fade effect
      background: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
      zIndex: 1, // Ensure the fade effect is on top of the image
    }}
  ></div>

  <div className="container" style={{ position: "relative", zIndex: 2 }}>
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-7 col-md-7">
        <div className="slider-content s-slider-content mt-80 text-center">
          <h2
            className={`${
              animation && "animation" && "fadeInUp"
            } `}
            data-animation="fadeInUp"
            data-delay=".4s"
            style={{ animationDelay: "0.4s" }}
          >
            {e.title}
          </h2>
          <p data-animation="fadeInUp" data-delay=".6s">
            {e.description}
          </p>
          {e.youtube && (
            <div className="slider-btn mt-30 mb-105">
              <Link
                to="#"
                onClick={() => {
                  sethShow(true);
                  console.log(e);
                  setYoutube(e.youtube);
                }}
                className="video-i popup-video"
                data-animation="fadeInUp"
                data-delay=".8s"
                style={{ animationDelay: "0.8s" }}
                tabIndex="0"
              >
                <i className="fas fa-play"></i> Intro Video
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
</div>

                  {/* </div> */}
                </>
              );
            })}

            {/* <div className="col-xl-2">
                            <div className="single-slider slider-bg d-flex align-items-center" style={{ backgroundImage: `url(https://basilresort.s3.ap-south-1.amazonaws.com/Banners/BannerImages/-63bbd18d6331ca1a951781d1)`, background_size: "cover" }}>
                                <div className="container">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-lg-7 col-md-7">
                                            <div className="slider-content s-slider-content mt-80 text-center">
                                                <h2 data-animation="fadeInUp" data-delay=".4s" style={{ animationDelay: "0.4s" }}>Enjoy A Luxuary Experience</h2>
                                                <p data-animation="fadeInUp" data-delay=".6s">Donec vitae libero non enim placerat eleifend aliquam erat volutpat. Curabitur diam ex, dapibus purus sapien, cursus sed nisl tristique, commodo gravida lectus non.</p>

                                                <div className="slider-btn mt-30 mb-105">
                                                    <Link to="/contact" className="btn ss-btn active mr-15" data-animation="fadeInLeft" data-delay=".4s">Discover More </Link>
                                                    <Link to="#" onClick={() => sethShow(true)} className="video-i popup-video" data-animation="fadeInUp" data-delay=".8s" style={{ animation_delay: " 0.8s" }} tabIndex="0"><i className="fas fa-play"></i> Intro Video</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </Slider>
          <div className="xl-none lg-none ps-5 pe-5 ms-5 me-5 d-lg-none d-xl-none">
                  <Link
                    onClick={() => setOpenModal(true)}
                    className="top-btn mt-10 mb-10"
                    style={{ background: "#85ab00" }}
                  >
                    Book Now
                  </Link>
                </div>
          <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customStyles}
          contentLabel="Book a Room"
        >
          <div className="col-md-12">
            <div className="sidebar-widget categories">
              <div className="widget-content">
                <h2 className="widget-title" style={{ color: "#85ab00" }}>
                  Book A Room
                </h2>
                <div className="contact-field p-relative c-name mb-20">
                  <label className="mb-0">
                    <i className="fal fa-phone"></i> Call us
                  </label>
                  <a href="tel:9061831733" style={{ cursor: "pointer" }}>
                    <h4>9778434442</h4>
                  </a>
                </div>
                <div className="contact-field p-relative c-name mb-20">
                  <label className="mb-0">
                    <i className="fal fa-envelope"></i> Mail us
                  </label>
                  <a
                    href="mailto:info@sandalmistresort.com?subject=Cottage Price Enquiry&body=Hello!"
                    style={{ cursor: "pointer" }}
                  >
                    <h4>info@sandalmistresort.com</h4>
                  </a>
                </div>
                <div className="contact-field p-relative c-name mb-20">
                  <label className="mb-0">
                    <i
                      className="fab fa-whatsapp"
                      style={{ color: "green" }}
                    ></i>{" "}
                    Text us on WhatsApp
                  </label>
                  <a
                    href="https://api.whatsapp.com/send?phone=9778434442"
                    style={{ cursor: "pointer" }}
                  >
                    <h4>9778434442</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setOpenModal(false)}
            className="btn btn-secondary"
          >
            Close
          </button>
        </Modal>
        </section>
        
        {/* check availability component */}
        {/* <div id="booking" className="booking-area p-relative">
                    <div className="container">
                        <form action="#" className="contact-form" >
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <ul>
                                        <li>
                                            <div className="contact-field p-relative c-name">
                                                <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                                <input type="date" id="chackin" name="date" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-field p-relative c-name">
                                                <label><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                                <input type="date" id="chackout" name="date" />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-field p-relative c-name">
                                                <label><i className="fal fa-users"></i> Adults</label>
                                                <select name="adults" id="adu">
                                                    <option value="sports-massage">Adults</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-field p-relative c-name">
                                                <label><i className="fal fa-baby"></i> Child</label>
                                                <select name="child" id="cld">
                                                    <option value="sports-massage">Child</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-field p-relative c-name">
                                                <label><i className="fal fa-concierge-bell"></i> Room</label>
                                                <select name="room" id="rm">
                                                    <option value="sports-massage">Room</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="slider-btn">
                                                <label><i className="fal fa-calendar-alt"></i></label>
                                                <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s">Check Availability</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}

<section className="about-area about-p pt-50 pb-120 p-relative fix">

  <div className="animations-02">
    <img src={About} alt="contact-bg-an-02" />
  </div>
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-6 col-md-12 col-sm-12" >
      <div
  className="s-about-img p-relative wow fadeInLeft animated"
  data-animation="fadeInLeft"
  data-delay=".4s"
  style={{
    position: 'relative',
    overflow: 'hidden',
  }}
>
<img
  src={About1}
  alt="img"
  style={{
    transition: 'transform 0.3s ease',
    maxWidth:'90vh',
    maxHeight:"90vh",
  }}
  onMouseMove={(e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;  // Horizontal position in percentage
    const y = (e.clientY - rect.top) / rect.height;  // Vertical position in percentage
    const moveX = (x - 0.5) * 20;  // Adjust 20px based on mouse position
    const moveY = (y - 0.5) * 20;  // Adjust 20px based on mouse position
    e.currentTarget.style.transform = `translate(${moveX}px, ${moveY}px) scale(1.05)`;
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'translate(0, 0) scale(1)';
  }}
/>

  <div className="about-icon">
    <img
      src={About2}
      // alt="img"
      style={{
        transition: 'transform 0.3s ease',
        maxWidth:'70vh',
        maxHeight:"70vh",
      }}
      onMouseMove={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = (e.clientX - rect.left) / rect.width;  // Horizontal position in percentage
        const y = (e.clientY - rect.top) / rect.height;  // Vertical position in percentage
        const moveX = (x - 0.5) * 20;  // Adjust 20px based on mouse position
        const moveY = (y - 0.5) * 20;  // Adjust 20px based on mouse position
        e.currentTarget.style.transform = `translate(${moveX}px, ${moveY}px) scale(1.05)`;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translate(0, 0) scale(1)';
      }}
      onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
      onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
    />
  </div>
</div>

      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div
          className="about-content s-about-content wow fadeInRight animated pl-30"
          data-animation="fadeInRight"
          data-delay=".4s"
          
        >
          <div className="about-title second-title pb-25">
            <h5>About Us</h5>
            <h3>Sandalmist - The hilltop habitat</h3>
          </div>
          <h5>
            Welcome to SandalMist, Your Tranquil Escape in the Heart of
            Munnad, Kasargod, Kerala.
          </h5>
          <p>
            Nestled in the serene landscapes of Munnad, Kasargod,
            SandalMist offers a perfect blend of natural beauty and
            luxurious comfort. Our resort is a haven for those looking
            to escape the hustle and bustle of everyday life and immerse
            themselves in the pristine beauty of Kerala’s countryside.
          </p>
          <h5>Our Story</h5>
          <p>
            SandalMist was born out of a passion for hospitality and a
            deep love for Kerala's natural splendor. Located in the
            quaint village of Munnad, our resort was designed to be a
            peaceful retreat where guests can connect with nature,
            unwind, and rejuvenate their minds and bodies. Every aspect
            of our resort reflects our commitment to providing an
            authentic and immersive experience in Kerala’s unspoiled
            wilderness.
          </p>

          {/* Moved What We Offer Section */}
          
          <div className="about-content3 mt-30">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12">
                <h4>What We Offer</h4>
                <ul className="green mb-30">
                  <p>
                    At SandalMist, we believe in creating unforgettable
                    experiences for our guests. Our resort features:
                  </p>
                  <li>
                    Comfortable Accommodations: Choose from our range of
                    well-appointed rooms, suites, and cottages, each
                    designed to provide a restful and luxurious stay.
                  </li>
                  <li>
                    Delectable Cuisine: Enjoy a taste of Kerala with our
                    carefully curated menu, featuring traditional and
                    contemporary dishes made from the freshest local
                    ingredients.
                  </li>
                  <li>
                    Wellness and Relaxation: Rejuvenate your senses at
                    our spa and wellness center, offering a variety of
                    treatments inspired by Ayurvedic practices.
                  </li>
                  <li>
                    Adventure and Exploration: Discover the beauty of
                    Kasargod through guided nature walks, bird watching,
                    trekking, and visits to nearby attractions like the
                    Bekal Fort, Ranipuram hills, and more.
                  </li>
                  <li>
                    Warm Hospitality: Our dedicated team is committed to
                    ensuring that your stay is comfortable, memorable,
                    and filled with warm hospitality.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="slider-btn">
                  {/* <Link
                    to={{pathname:`/about`,state:{stateParam:true} }}
                    className="btn ss-btn"
                  >
                    Discover More
                  </Link> */}
                  <Link to="/about" onClick={{}} className="btn ">
                  Discover More
                            </Link>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <div className="signature">
                  {/* <img src={About3} alt="img" /> */}
                </div>
              </div>
            </div>
          </div>
          {/* End of Moved Section */}

        </div>
      </div>
    </div>
  </div>
</section>

        

        <section
          id="service-details2"
          className="pt-120 pb-90 p-relative"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-01">
            <img src={Hotel} alt="an-img-01" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h5>Explore</h5>
                  <h3>The Activities</h3>
                  {/* <p>
                    Proin consectetur non dolor vitae pulvinar. Pellentesque
                    sollicitudin dolor eget neque viverra, sed interdum metus
                    interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper
                    dolor iaculis vel
                  </p> */}
                </div>
              </div>

              {ActivitiesList.map((activity, key) => {
  return (
    <div
      className="col-lg-4 col-md-6"
      key={key}
      style={{
        position: 'relative',
        overflow: 'hidden',
        transition: 'transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1)', // Smooth transition
      }}
      onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.03)'} // Slight zoom
      onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'} // Reset zoom
    >
      <div className="services-08-item mb-30">
        <div className="services-icon2">
          <img src={sandalmist} alt="img" />
        </div>
        <div className="services-08-thumb">
          <img
            src={
              activity.thumbnailImage.length > 0
                ? activity.thumbnailImage[0]
                : Hotel2
            }
            alt="img"
          />
        </div>
        <div className="services-08-content">
          <h4>
            <Link
              to={{
                pathname: `/activity-details/${activity._id}`,
                state: { stateParam: true },
              }}
            >
              {activity.title}
            </Link>
          </h4>
          <p>
            {activity.paragraph1.length > 70
              ? activity.paragraph1.substring(0, 70) + "..."
              : activity.paragraph1}
          </p>
          <Link
            to={{
              pathname: `/activity-details/${activity._id}`,
              state: { stateParam: true },
            }}
          >
            Read More <i className="fal fa-long-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
})}


              {/* <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={sandalmist} alt="img" />
                                    </div>
                                    <div className="services-08-thumb">
                                      
                                        <img src={"https://basilresort.s3.ap-south-1.amazonaws.com/Banners/BannerImages/90-638ee7342771e705c4353d7f"} alt="img" />


                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/activity-details"> Spa & Wellness</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                        <Link to="/activity-details">Read More <i className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div> */}

              {/* <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={sandalmist} alt="img" />
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Hotel3} alt="img" />
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/activity-details">Best Accommodation</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                        <Link to="/activity-details">Read More <i className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={sandalmist} alt="img" />
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Hotel4} alt="img" />
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/activity-details"> Wellness & Spa</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                        <Link to="/activity-details">Read More <i className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={sandalmist} alt="img" />
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Hotel5} alt="img" />
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/activity-details">Restaurants & Bars</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                        <Link to="/activity-details">Read More <i className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={sandalmist} alt="img" />
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Hotel6} alt="img" />
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/activity-details">Special Offers</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                        <Link to="/activity-details">Read More <i className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </section>
        <section id="services" className="services-area pt-113 pb-150">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="section-title center-align mb-50 text-center">
          <h5>The pleasure of luxury</h5>
          <h3>Rooms & Suites</h3>
          {/* <p>
            Proin consectetur non dolor vitae pulvinar. Pellentesque
            sollicitudin dolor eget neque viverra, sed interdum metus
            interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper
            dolor iaculis vel
          </p> */}
        </div>
      </div>
    </div>
            <Slider className="row services-active" {...service}>
              {cottagesList.map((cottage) => {
  return (
    <div
      className="col-xl-4 col-md-6"
      style={{
        position: 'relative', // For positioning relative to this container
        overflow: 'hidden', // Hide overflow of zoomed image
      }}
    >
      <div className="single-services mb-30">
        <div
          className="services-thumb"
          style={{
            overflow: 'hidden',
            position: 'relative', // Required for positioning the image within this container
          }}
        >
          <img
            src={cottage.cottageImages[0]} // Ensure this path is correct
            alt="img"
            style={{
              display: 'block',
              width: '100%',
              height: 'auto',
              transition: 'transform 0.2s ease, box-shadow 0.5s ease', // Smooth transition
              transform: 'scale(1)', // Default scale
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Default shadow
              position: 'relative', // Adjust positioning for smooth effect
            }}
            onMouseMove={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;
              e.currentTarget.style.transform = `scale(1.07) translate(${(x - rect.width / 2) / 20}px, ${(y - rect.height / 2) / 20}px)`;
              e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)'; // Enhanced shadow on hover
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'scale(1)'; // Reset zoom effect
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)'; // Reset shadow
            }}
          />
        </div>
        <div className="services-content">
          <div className="day-book">
            <ul>
              <li>₹{cottage.price ?? 0}/Night</li>
              <li>
                <Link
                  to={{
                    pathname: `/room-details/${cottage._id}`,
                    state: { stateParam: true },
                  }}
                >
                  Book Now
                </Link>
              </li>
            </ul>
          </div>
          <h4>
            <Link
              to={{
                pathname: `/room-details/${cottage._id}`,
                state: { stateParam: true },
              }}
            >
              {cottage.cottageType}
            </Link>
          </h4>
          <div className="icon">
            <ul>
              <li><img src={icon1} alt="img" /></li>
              <li><img src={icon2} alt="img" /></li>
              <li><img src={icon3} alt="img" /></li>
              <li><img src={icon4} alt="img" /></li>
              <li><img src={icon5} alt="img" /></li>
              <li><img src={icon6} alt="img" /></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
})}


              {/* <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <Link to="#" className="gallery-link popup-image" onClick={() => {
                                            setToggler(!toggler)
                                            setActiveImage(1)
                                        }}>
                                            <img src={service1} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>$600/Night</li>
                                                <li><Link to="/contact">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4><Link to="/room-details">classNameic Balcony Room</Link></h4>
                                        <p>Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.</p>
                                        <div className="icon">
                                            <ul>
                                                <li><img src={icon1} alt="img" /></li>
                                                <li><img src={icon2} alt="img" /></li>
                                                <li><img src={icon3} alt="img" /></li>
                                                <li><img src={icon4} alt="img" /></li>
                                                <li><img src={icon5} alt="img" /></li>
                                                <li><img src={icon6} alt="img" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <Link to="#" className="gallery-link popup-image" onClick={() => {
                                            setToggler(!toggler)
                                            setActiveImage(1)
                                        }}>
                                            <img src={service1} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>$600/Night</li>
                                                <li><Link to="/contact">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4><Link to="/room-details">classNameic Balcony Room</Link></h4>
                                        <p>Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.</p>
                                        <div className="icon">
                                            <ul>
                                                <li><img src={icon1} alt="img" /></li>
                                                <li><img src={icon2} alt="img" /></li>
                                                <li><img src={icon3} alt="img" /></li>
                                                <li><img src={icon4} alt="img" /></li>
                                                <li><img src={icon5} alt="img" /></li>
                                                <li><img src={icon6} alt="img" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <Link to="#" className="gallery-link popup-image" onClick={() => {
                                            setToggler(!toggler)
                                            setActiveImage(2)
                                        }}>
                                            <img src={service2} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>$400/Night</li>
                                                <li><Link to="/contact">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4><Link to="/room-details">Superior Double Room</Link></h4>
                                        <p>Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at.</p>
                                        <div className="icon">
                                            <ul>
                                                <li><img src={icon1} alt="img" /></li>
                                                <li><img src={icon2} alt="img" /></li>
                                                <li><img src={icon3} alt="img" /></li>
                                                <li><img src={icon4} alt="img" /></li>
                                                <li><img src={icon5} alt="img" /></li>
                                                <li><img src={icon6} alt="img" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <Link to="#" className="gallery-link popup-image" onClick={() => {
                                            setToggler(!toggler)
                                            setActiveImage(4)
                                        }}>
                                            <img src={service4} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>$300/Night</li>
                                                <li><Link to="/contact">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4><Link to="/room-details">Delux Double Room</Link></h4>
                                        <p>Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.</p>
                                        <div className="icon">
                                            <ul>
                                                <li><img src={icon1} alt="img" /></li>
                                                <li><img src={icon2} alt="img" /></li>
                                                <li><img src={icon3} alt="img" /></li>
                                                <li><img src={icon4} alt="img" /></li>
                                                <li><img src={icon5} alt="img" /></li>
                                                <li><img src={icon6} alt="img" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </Slider>
          </div>
        </section>
        {/* <section className="feature-area2 p-relative fix" style={{background: "#f7f5f1"}}>
                 <div className="animations-02"><img src={Resort} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                         <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                           <div className="feature-img">                               
                                <img src={Resort1} alt="img" className="img"/>              
                            </div>
                        </div>
					   <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="feature-content s-about-content">
                                <div className="feature-title pb-20">                               
                                    <h5>Luxury Hotel & Resort</h5>
                                <h2>
                                 Pearl Of The Adriatic.
                                </h2>                             
                                </div>
                                <p>Vestibulum non ornare nunc. Maecenas a metus in est iaculis pretium. Aliquam ullamcorper nibh lacus, ac suscipit ipsum consequat porttitor.Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at.</p>
                                <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p>
                                <div className="slider-btn mt-15">                                          
                                    <Link to="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        {/* <section id="pricing" className="pricing-area pt-120 pb-60 fix p-relative">
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="animations-02"><img src={pricing1} alt="contact-bg-an-01"/></div>
                <div className="container"> 
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="section-title mb-20">
                                <h5>Best Prices</h5>
                                <h2>Extra Services</h2>                               
                            </div>
                            <p>Proin consectetur non dolor vitae pulvinar. Pellentesque sollicitudin dolor eget neque viverra, sed interdum metus interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper dolor iaculis vel</p>
                            <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">  
                                    <h3>Room cleaning</h3>    
                                    <p>Perfect for early-stage startups</p>
                                    <div className="month">Monthly</div>
                                    <div className="price-count">
                                        <h2>₹3999.99</h2>
                                    </div> 
                                    <hr/>
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <ul>
                                        <li>Hotel quis justo at lorem</li>
                                        <li>Fusce sodales, urna et tempus</li>
                                        <li>Vestibulum blandit lorem quis</li>                                           
                                    </ul>
                                </div>  
                                <div className="pricing-btn">
                                    <Link to="/contact" className="btn ss-btn">Get Started <i className="fal fa-angle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                         <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">  
                                    <h3>Drinks included</h3>    
                                    <p>Perfect for early-stage startups</p>
                                    <div className="month">Monthly</div>
                                    <div className="price-count">
                                        <h2>₹5999.99</h2>
                                    </div> 
                                    <hr/>
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <ul>
                                        <li>Hotel quis justo at lorem</li>
                                        <li>Fusce sodales, urna et tempus</li>
                                        <li>Vestibulum blandit lorem quis</li> 
                                    </ul>
                                </div> 
                                <div className="pricing-btn">
                                    <Link to="/contact" className="btn ss-btn">Get Started <i className="fal fa-angle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        <section
  className="testimonial-area pt-5 pb-5"
  style={{
    backgroundImage: `url(${BGTest})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflowX: "hidden",
    position: "relative",
  }}
>
  <div className="container">
    <div className="row text-center mb-5">
      <div className="col-12">
        <h5 className="text-white">Testimonial</h5>
        <h3 className="text-white">What Our Clients Say</h3>
      </div>
    </div>
    <div
      className="row"
      style={{
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        WebkitOverflowScrolling: "touch",
        paddingBottom: "10px",
      }}
    >
      {testimonialsList.map((testimony, key) => (
        <div
          className="single-testimonial p-4"
          key={key}
          style={{
            flex: "0 0 auto",
            width: "300px",
            marginRight: "15px",
            transition: "transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out, border-color 0.4s ease-in-out",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
            borderRadius: "12px",
            cursor: "pointer",
            scrollSnapAlign: "start",
            background: "linear-gradient(135deg, #f5f5f5, #ffffff)",
            color: "#000",
            position: "relative",
            border: "2px solid transparent", // Initial border
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 12px 24px rgba(0, 0, 0, 0.5)";
            e.currentTarget.style.borderColor = "#85ab00"; // Change border color on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.3)";
            e.currentTarget.style.borderColor = "transparent"; // Revert border color
          }}
        >
          <div className="d-flex align-items-center mb-3">
            <img
              src={testimony.avatar ? testimony.avatar : avatar1}
              className="rounded-circle me-3"
              width={60}
              height={60}
              alt="author"
            />
            <div>
              <h6 style={{ margin: 0, color: "#000" }}>{testimony.name}</h6>
              <span style={{ color: "#000" }}>Client</span>
            </div>
          </div>
          <p>
            {testimony.testimony.length > 200
              ? `${testimony.testimony.substring(0, 150)}...`
              : testimony.testimony}
          </p>
        </div>
      ))}
    </div>
    {showTooltip && (
      <div
        style={{
          position: "absolute",
          right: "70px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "#000",
          color: "#fff",
          padding: "5px 10px",
          borderRadius: "4px",
          zIndex: 20,
        }}
      >
        Scroll
      </div>
    )}
  </div>
</section>


        

        {/* <section className="booking pt-120 pb-120 p-relative fix">
    <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
                 <div className="contact-bg02">
                    <div className="section-title center-align">
                        <h5>make appointment</h5>
                        <h2>
                           Book A Room
                        </h2>
                    </div>                                
                    <form action="mail.php" method="post" className="contact-form mt-30">
                        <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="contact-field p-relative c-name mb-20">                                    
                               <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                <input type="date" id="chackin2" name="date" />
                            </div>                               
                        </div>

                        <div className="col-lg-6 col-md-6">                               
                            <div className="contact-field p-relative c-subject mb-20">                                   
                               <label><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                <input type="date" id="chackout2" name="date" />
                            </div>
                        </div>		
                        <div className="col-lg-6 col-md-6">                               
                            <div className="contact-field p-relative c-subject mb-20">                                   
                                <label><i className="fal fa-users"></i> Adults</label>
                                <select name="adults" id="adu2">
                                    <option value="sports-massage">Adults</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>	
                        <div className="col-lg-6 col-md-6">                               
                            <div className="contact-field p-relative c-option mb-20">                                   
                                <label><i className="fal fa-concierge-bell"></i> Room</label>
                                <select name="room" id="rm2">
                                    <option value="sports-massage">Room</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="slider-btn mt-15">                                          
                                <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s"><span>Book Table Now</span></button>				
                            </div>                             
                        </div>
                    </div>
                </form>                            
                </div>  
                     
            </div>
            <div className="col-lg-6 col-md-6">
                 <div className="booking-img">
                     <img src={Booking} alt="img"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="video" className="video-area pt-150 pb-150 p-relative" style={{backgroundImage: `url(${BGVideo})`, background_repeat: "no-repeat", background_position: "bottom", background_size:"cover"}}>
    <div className="content-lines-wrapper2">
        <div className="content-lines-inner2">
            <div className="content-lines2"></div>
        </div>
    </div>
    <div className="container">                   
         <div className="row">
            <div className="col-12">
                <div className="video-wrapper text-center">   
                    <div className="video-content">  
                        <h2 className="text-white">Watch Our</h2>
                        <h2 className="text-white">Introducing Video</h2>
                        <div className="video-icon mt-40">
                            <a className="popup-video" href={videoLink}><i className="fal fa-play"></i></a>                                 
                        </div>        
                    </div>               
                </div>                     
            </div>                     
        </div>
    </div>           
</section> */}
         <section
        className="my-section"
        style={{
          padding: 0, // Remove padding from the section
          margin: 0, // Remove margin from the section
          overflow: 'hidden', // Ensure the image stays within the section
        }}
      >
        <div className="container-fluid" style={{ padding: 0 }}>
          <div className="row no-gutters">
            <div className="col-12" style={{ padding: 0 }}>
              {/* Ensure the image covers the full width */}
              <img
                src={keralaImg}
                alt="Kerala"
                className="parallax-image"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  margin: "0 auto",
                  opacity: "10%",
                  transition: "transform 0.2s ease-out",
                }}
              />
            </div>
          </div>
        </div>
      </section>

        {/* <div className="brand-area pt-60 pb-60"  style={{background :"#f7f5f1"}}>
                <div className="container">
                    <Slider className="row brand-active" {...brand}>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Brand1} alt="img"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                 <img src={Brand2} alt="img"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                 <img src={Brand3} alt="img"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                  <img src={Brand4} alt="img"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                 <img src={Brand5} alt="img"/>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div> */}
      </main>

      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />

      {yShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setyShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src="//www.youtube.com/embed/bjOjD-QucyA?autoplay=1"
                    frameborder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}

      {hShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => sethShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    className="mfp-iframe"
                    src={youtubeVideo}
                    autoPlay
                    frameBorder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Main;
