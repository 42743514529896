import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BGImg from "../../assets/img/bg/aboutus-ban.jpg";
import axios from "axios";
import config from "../../services/config";
import { useDispatch } from "react-redux";
import Blog from "../../assets/img/bg/an-img-06.png";
import About1 from "../../assets/img/resort/resortimage-1.jpg";
import About2 from "../../assets/img/resort/resortimage-2.jpg";
import uniqueImg from "../../assets/img/features/feature.png";
import ayurvedaImg from "../../assets/img/features/Ayurvedic.jpg";
import keralaImg from "../../assets/img/features/kerala.jpg";
import puliImg from "../../assets/img/features/pulikali.jpg";
import commitImg from "../../assets/img/features/commitment.png";
import natureImg from "../../assets/img/features/nature.jpg";
import welcomeImg from "../../assets/img/features/welcome.webp";
import cottageImg from "../../assets/img/features/cottage.jpg";


// var blogsList=[];
const Main = () => {
  useEffect(() => {
    getRecentBlogsAPI();
    
    
  }, []);
 
  

  

  const [blogsList, setBlogList] = useState([]);

  function getRecentBlogsAPI() {
    axios({
      url: config.baseUrl + "getRecentBlogs",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.status == 200) {
          // blogsList = res.data.data;
          setBlogList(res.data.data);
          // console.log('blogsList: ', blogsList);
        } else if (res.status == 201) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.toString());
        
      });
  }

  function monthDateYear(dates) {
    const date = new Date(dates);
    const monthName =
      date.toString().split(" ")[2] +
      ", " +
      date.toString().split(" ")[1] +
      " " +
      date.toString().split(" ")[3];
    return monthName;
  }

    // Define the hovered state and setter function
    const [hovered, setHovered] = useState(false);
    const [hovered1, setHovered1] = useState(false);
    const [hovered2, setHovered2] = useState(false);
    const [hovered3, setHovered3] = useState(false);
    const [hovered4, setHovered4] = useState(false);
    const [hovered5, setHovered5] = useState(false);
    const [hovered6, setHovered6] = useState(false);
    const [hovered7, setHovered7] = useState(false);
    

    const imgStyle = {
      width: window.innerWidth <= 768 ? "100%" : "80%", // Adjust width for mobile
      height: "auto",
      borderRadius: "30px",
      padding: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      transform: hovered ? "scale(1.05)" : "scale(1)",
      
    };
  const imgStyle1 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered1 ? "scale(1.05)" : "scale(1)",
    
    
  };
  const imgStyle2 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered2 ? "scale(1.05)" : "scale(1)",
    
    
  };
  const imgStyle3 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered3 ? "scale(1.05)" : "scale(1)",

    
    
    
  };
  const imgStyle4 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered4 ? "scale(1.05)" : "scale(1)",
    
    
  };
  const imgStyle5 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered5 ? "scale(1.05)" : "scale(1)",
    
    
  };
  const imgStyle6 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered6 ? "scale(1.05)" : "scale(1)",
    
    
  };
  const imgStyle7 = {
    width: window.innerWidth <= 768 ? "100%" : "80%",
    height: "auto",
   borderRadius: "30px",
    padding: "15px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: hovered7 ? "scale(1.05)" : "scale(1)",
    
    
  };
  
  return (
    <>
      <main >
      <section
  className="breadcrumb-area d-flex align-items-center"
  style={{
    background: `url(${BGImg}) no-repeat center center/cover`,
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2
              style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                textShadow:
                  "",
              }}
            >
              About Us
            </h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    id="top"
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        <section className="about-area about-p pt-120 pb-120 p-relative fix">
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-02" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="s-about-img p-relative  wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <img
  src={About1}
  alt="img"
  style={{
    transition: 'transform 0.3s ease',
    maxWidth:'90vh',
    maxHeight:"90vh",
  }}
  onMouseMove={(e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;  // Horizontal position in percentage
    const y = (e.clientY - rect.top) / rect.height;  // Vertical position in percentage
    const moveX = (x - 0.5) * 20;  // Adjust 20px based on mouse position
    const moveY = (y - 0.5) * 20;  // Adjust 20px based on mouse position
    e.currentTarget.style.transform = `translate(${moveX}px, ${moveY}px) scale(1.05)`;
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'translate(0, 0) scale(1)';
  }}
/>

  <div className="about-icon">
    <img
      src={About2}
      // alt="img"
      style={{
        transition: 'transform 0.3s ease',
        maxWidth:'70vh',
        maxHeight:"70vh",
      }}
      onMouseMove={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = (e.clientX - rect.left) / rect.width;  // Horizontal position in percentage
        const y = (e.clientY - rect.top) / rect.height;  // Vertical position in percentage
        const moveX = (x - 0.5) * 20;  // Adjust 20px based on mouse position
        const moveY = (y - 0.5) * 20;  // Adjust 20px based on mouse position
        e.currentTarget.style.transform = `translate(${moveX}px, ${moveY}px) scale(1.05)`;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translate(0, 0) scale(1)';
      }}
      onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
      onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
    />
  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="about-content s-about-content  wow fadeInRight  animated pl-30"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div className="about-title second-title pb-25">
                    <h4>About Us</h4>
                    <h3>Welcome to SandalMist !!!</h3>
                  </div>
                  <p>
                    Nestled on a picturesque hilltop in the enchanting town of
                    Kasargod, Kerala, SandalMist is a haven of luxury and
                    tranquility. Our resort offers an unrivaled escape from the
                    everyday, blending the stunning natural beauty of Kerala
                    with the comfort and elegance of premium accommodations.
                  </p>
                  {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget lorem tempus blandit. Aenean eu vulputate lorem, quis auctor lectus.</p> */}
                  {/* <div className="about-content3 mt-30">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-12">
                                            <ul className="green mb-30">                                              
                                                <li> 24 Month / 24,000km Nationwide Warranty monotone</li>
                                                <li> Curabitur dapibus nisl a urna congue, in pharetra urna accumsan.</li>     
                                                <li> Customer Rewards Program and excellent technology</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="signature">
                                                <img src="assets/img/features/signature.png" alt="img" />     
                                                <h4 className="mt-10">Liquidlab</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="skill" className="skill-area p-relative fix" style={{background:"#291d16"}}>
                <div className="animations-01"><img src="assets/img/bg/an-img-05.png" alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
					   <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="skills-content s-about-content">
                                <div className="skills-title pb-20">                               
                                    <h5>Rio We Use</h5>
                                    <h2>
                                    We Offer Wide Selection of Hotel
                                    </h2>                             
                                </div>
                                <p>Vestibulum non ornare nunc. Maecenas a metus in est iaculis pretium. Aliquam ullamcorper nibh lacus, ac suscipit ipsum consequat porttitor.</p>
                                <div className="skills-content s-about-content mt-20">
                                    <div className="skills">
                                        <div className="skill mb-30">
                                        <div className="skill-name">Qulity Production</div>
                                        <div className="skill-bar">
                                            <div className="skill-per" id="80" style={{width: "80%"}}></div>
                                        </div>
                                        </div>
                                        <div className="skill mb-30">
                                        <div className="skill-name">Maintenance Services</div>
                                        <div className="skill-bar">
                                            <div className="skill-per" id="90" style={{width: "90%"}}></div>
                                        </div>
                                        </div>
                                        <div className="skill mb-30">
                                        <div className="skill-name">Product Managment</div>
                                        <div className="skill-bar">
                                            <div className="skill-per" id="70" style={{width: "70%"}}></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                            <div className="skills-img wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">                              
                                <img src="assets/img/bg/skills-img.png" alt="img" className="img"/>              
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={uniqueImg} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20"> */}
        {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Our Unique Location</h2>
                  </div>
                  <p>
                    Perched atop a serene hill in Munnad town called Shankaran
                    Kunnu”, SandalMist provides breathtaking panoramic views of
                    the lush landscapes and rolling hills that define the
                    region. The resort’s elevated position ensures a refreshing
                    sandal breeze and spectacular vistas that change with the
                    seasons, creating a picturesque setting for relaxation and
                    rejuvenation.
                  </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img
                    src="assets/img/features/feature.png"
                    alt="img"
                    className="img"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20">
                    {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Premium Cottages</h2>
                  </div>
                  <p>
                    Perched atop a serene hill in Munnad town called Shankaran
                    Kunnu”, SandalMist provides breathtaking panoramic views of
                    the lush landscapes and rolling hills that define the
                    region. The resort’s elevated position ensures a refreshing
                    sandal breeze and spectacular vistas that change with the
                    seasons, creating a picturesque setting for relaxation and
                    rejuvenation.
                  </p>
                  {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section>   */}

        <div
          className="container-fluid"
          style={{
            padding: "15px",
            fontFamily: "Arial, sans-serif",
            background: "#f4f4f4",
            color: "#333",
          }}onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div className="row">
          <div
      className="col-md-6"
      style={{ padding: "", textAlign: "center" , borderRadius: "10px",
        boxShadow: hovered ? "0 px px rgba(0, 0, 0, 10)" : "",
        }}
        
    >
      <img
        src={uniqueImg}
        alt="Bridge"
        style={imgStyle}
        
      />
    </div>
            <div
              className="col-md-6"
              style={{    display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",textAlign: "center" }}
            >
              <h3
                style={{
                  
                  marginBottom: "20px",
                  color: "#222",
                  fontWeight: "bold",
                }}
              >
                Our Unique Location
              </h3>
              <p
                style={{
                  
                  lineHeight: "1.8",
                  color: "#555",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                Perched atop a serene hill in Munnad town called Shankarankunnu,
                "SandalMist" provides breathtaking panoramic views of the lush
                landscape and rolling hills that define the region. The resort's
                elevated position ensures a refreshing sandal breeze and
                spectacular vistas that change with the seasons, creating a
                picturesque setting for relaxation and rejuvenation.
              </p>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-5"
          style={{
            padding: "15px",
            fontFamily: "Arial, sans-serif",
            background: "",
            color: "#333",
          }}onMouseEnter={() => setHovered1(true)}
                onMouseLeave={() => setHovered1(false)}
        >
          <div className="row">
            <div
              className="col-md-6"
              style={{     display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",textAlign: "center" }}
            >
              <h3
                style={{
                  
                  marginBottom: "20px",
                  color: "#222",
                  fontWeight: "bold",
                }}
              >
                Premium Cottages
              </h3>
              <p
                style={{
                  
                  lineHeight: "1.8",
                  color: "#555",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                Perched atop a serene hill in Munnad town called Shankaran
                Kunnu, SandalMist provides breathtaking panoramic views of the
                lush landscapes and rolling hills that define the region. The
                resort’s elevated position ensures a refreshing sandal breeze
                and spectacular vistas that change with the seasons, creating a
                picturesque setting for relaxation and rejuvenation.
              </p>
            </div>
            <div
              className="col-md-6"
              style={{    display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center", textAlign: "center" }}
            >
              <img
                src={cottageImg}
                alt="Cottage"
                style={imgStyle1}
                
      
              />
            </div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{
            padding: "15px",
            fontFamily: "Arial, sans-serif",
            background: "#f4f4f4",
            color: "#333",
          }} onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
        >
          <div className="row">
            <div
              className="col-md-6"
              style={{     display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",textAlign: "center" }}
            >
              <img
                src={ayurvedaImg}
                alt="Bridge"
                style={imgStyle2}
       
              />
            </div>
            <div
              className="col-md-6"
              style={{    display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center", textAlign: "center" }}
            >
              <h3
                style={{
                  
                  marginBottom: "20px",
                  color: "#222",
                  fontWeight: "bold",
                }}
              >
                Essence of Wellness with Our Ayurvedic Spa Treatments
              </h3>
              <p
                style={{
                  
                  lineHeight: "1.8",
                  color: "#555",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                Our Ayurvedic spa offers a range of rejuvenating treatments
                designed to restore balance and harmony to your body, mind, and
                spirit. Whether you're seeking relaxation, detoxification, or
                revitalization, our skilled therapists tailor each experience to
                your unique needs. A luxurious full-body massage with warm
                herbal oils that promotes deep relaxation and detoxification.A
                soothing therapy where warm oil is gently poured over the
                forehead to calm the mind and enhance mental clarity.
              </p>
            </div>
          </div>
        </div>

        <div
          className="container-fluid mt-5"
          style={{
            padding: "15px",
            fontFamily: "Arial, sans-serif",
            background: "",
            color: "#333",
          }}onMouseEnter={() => setHovered3(true)}
          onMouseLeave={() => setHovered3(false)}
        >
          <div className="row">
            <div
              className="col-md-6"
              style={{    display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center", textAlign: "center" }}
            >
              <h3
                style={{
                  
                  marginBottom: "10px",
                  color: "#222",
                  fontWeight: "bold",
                }}
              >
                Discover Kerala
              </h3>
              <p
                style={{
                  
                  lineHeight: "1.8",
                  color: "#555",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                Kerala, often referred to as “God’s Own Country,” is renowned
                for its lush landscapes, serene backwaters, and vibrant culture.
                Our resort is situated in Kasargod, a region celebrated for its
                rich heritage and natural beauty. Here’s a glimpse of what you
                can explore during your stay: During your stay with us, you get
                a chance to visit the most vibrant and attractive tourist places
                in Kasargod such as Bekal fort, Ranipuram hill, Kappil Beach,
                Madhur temple , Chandragiri Fort etc.
              </p>
            </div>
            <div
              className="col-md-6"
              style={{    display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center", textAlign: "center" }}
            >
              <img
                src={keralaImg}
                alt="Cottage"
                style={imgStyle3}
        
              />
            </div>
          </div>
        </div>
        <div
  className="container-fluid"
  style={{
    padding: "15px",
    fontFamily: "Arial, sans-serif",
    background: "#f4f4f4",
    color: "#333",
  }}onMouseEnter={() => setHovered4(true)}
  onMouseLeave={() => setHovered4(false)}
>
  <div className="row align-items-center" >
    <div
      className="col-md-6"
      style={{ display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",textAlign: "center" }}
    >
      <img
        src={puliImg}
        alt="Bridge"
        style={imgStyle4}
        
      />
    </div>
    <div
      className="col-md-6"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          
          marginBottom: "20px",
          color: "#222",
          fontWeight: "bold",
        }}
      >
        Vibrant Culture and Festivals
      </h3>
      <p
        style={{
          
          lineHeight: "1.8",
          color: "#555",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        Kerala is known for its vibrant festivals and traditional arts. From the
        colorful boat races to the mesmerizing Kathakali performances, there’s
        always something to experience and celebrate. Theyyam is a traditional
        dance form that is performed in various temples and cultural festivals
        in northern Kerala, especially in Kasargod.
      </p>
    </div>
  </div>
</div>


        <div
  className="container-fluid mt-5"
  style={{
    padding: "15px",
    fontFamily: "Arial, sans-serif",
    background: "",
    color: "#333",
  }}onMouseEnter={() => setHovered5(true)}
  onMouseLeave={() => setHovered5(false)}
>
  <div className="row align-items-center">
    <div
      className="col-md-6"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          
          marginBottom: "20px",
          color: "#222",
          fontWeight: "bold",
        }}
      >
        Our Commitment to Excellence
      </h3>
      <p
        style={{
          
          lineHeight: "1.8",
          color: "#555",
          maxWidth: "600px",
        }}
      >
        At SandalMist, we are dedicated to providing our guests with an
        exceptional experience. Our team is committed to offering personalized
        service, ensuring that every aspect of your stay is nothing short of
        perfect. From the moment you arrive, you’ll be greeted with warm
        hospitality and a genuine desire to make your stay memorable.
      </p>
    </div>
    <div
      className="col-md-6"
      style={{ textAlign: "center" }}
    >
      <img
        src={commitImg}
        alt="Cottage"
        style={imgStyle5}
        
      />
    </div>
  </div>
</div>

<div
  className="container-fluid"
  style={{
    padding: "15px",
    fontFamily: "Arial, sans-serif",
    background: "#f4f4f4",
    color: "#333",
  }}  onMouseEnter={() => setHovered6(true)}
  onMouseLeave={() => setHovered6(false)}
>
  <div className="row align-items-center">
    <div
      className="col-md-6"
      style={{ textAlign: "center" }}
    >
      <img
        src={natureImg}
        alt="Bridge"
        style={imgStyle6}
      
      />
    </div>
    <div
      className="col-md-6"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          
          marginBottom: "20px",
          color: "#222",
          fontWeight: "bold",
        }}
      >
        Sustainability and Community Engagement
      </h3>
      <p
        style={{
          
          lineHeight: "1.8",
          color: "#555",
          maxWidth: "600px",
        }}
      >
        We believe in responsible tourism and are committed to preserving the
        natural beauty of our surroundings. Our eco-friendly practices and
        community engagement efforts reflect our dedication to sustainability
        and positive impact. By supporting local artisans and businesses, we
        contribute to the vibrant local economy and enrich the cultural
        experience for our guests.
      </p>
    </div>
  </div>
</div>

        <div
          className="container-fluid mt-5 mb-5"
          style={{
            padding: "15px",
            fontFamily: "Arial, sans-serif",
            background: "",
            color: "#333",
          }} onMouseEnter={() => setHovered7(true)}
          onMouseLeave={() => setHovered7(false)}
        >
          <div className="row">
            <div
              className="col-md-6"
              style={{display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",}}
            >
              <h3
                style={{
                  
                  marginBottom: "20px",
                  color: "#222",
                  fontWeight: "bold",
                  alignItems: "center"
                }}
              >
                Join Us for an Unforgettable Experience
              </h3>
              <p
                style={{
                  
                  lineHeight: "1.8",
                  color: "#555",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                Whether you’re seeking a romantic getaway, a peaceful retreat,
                or an adventure-filled holiday, SandalMist is the perfect
                destination. Immerse yourself in the natural beauty and cultural
                richness of Kerala while enjoying the luxurious comfort of our
                hilltop resort. We look forward to welcoming you to SandalMist,
                where every stay is a cherished memory waiting to be made.
              </p>
            </div>
            <div
              className="col-md-6"
              style={{ padding: "20px", textAlign: "center" }}
            >
              <img
                src={welcomeImg}
                alt="Cottage"
                style={imgStyle7}
        
              />
            </div>
          </div>
        </div>

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={ayurvedaImg} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20"> */}
        {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>
                      Essence of Wellness with Our Ayurvedic Spa Treatments
                    </h2>
                  </div>
                  <p> */}
        {/* Our Ayurvedic spa offers a range of rejuvenating treatments */}
        {/* designed to restore balance and harmony to your body, mind,
                    and spirit. Whether you're seeking relaxation,
                    detoxification, or revitalization, our skilled therapists
                    tailor each experience to your unique needs. A luxurious
                    full-body massage with warm herbal oils that promotes deep
                    relaxation and detoxification.A soothing therapy where warm
                    oil is gently poured over the forehead to calm the mind and
                    enhance mental clarity. .
                  </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={keralaImg} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20"> */}
        {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Discover Kerala</h2> */}
        {/* </div>
                  <p>
                    Kerala, often referred to as “God’s Own Country,” is
                    renowned for its lush landscapes, serene backwaters, and
                    vibrant culture. Our resort is situated in Kasargod, a
                    region celebrated for its rich heritage and natural beauty.
                    Here’s a glimpse of what you can explore during your stay:
                  </p>
                  <p>
                    During your stay with us, you get a chance to visit the most
                    vibrant and attractive tourist places in Kasargod such as
                    Bekal fort, Ranipuram hill, Kappil Beach, Madhur temple ,
                    Chandragiri Fort etc.
                  </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        > */}
        {/* <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={puliImg} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20"> */}
        {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Vibrant Culture and Festivals </h2>
                  </div>
                  <p>
                    Kerala is known for its vibrant festivals and traditional
                    arts. From the colorful boat races to the mesmerizing
                    Kathakali performances, there’s always something to
                    experience and celebrate.Theyyam is a traditional dance form
                    that is performed in various temples and cultural festivals
                    in northern Kerala, especially in Kasargod.
                  </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={commitImg} alt="img" className="img" />
                </div>
              </div> */}
        {/* <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20">
                    {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Our Commitment to Excellence </h2>
                  </div>
                  <p> */}
        {/* At SandalMist, we are dedicated to providing our guests with
                    an exceptional experience. Our team is committed to offering
                    personalized service, ensuring that every aspect of your
                    stay is nothing short of perfect. From the moment you
                    arrive, you’ll be greeted with warm hospitality and a
                    genuine desire to make your stay memorable.
                  </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={natureImg} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20"> */}
        {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Sustainability and Community Engagement </h2>
                  </div>
                  <p>
                    We believe in responsible tourism and are committed to
                    preserving the natural beauty of our surroundings. Our
                    eco-friendly practices and community engagement efforts
                    reflect our dedication to sustainability and positive
                    impact. By supporting local artisans and businesses, we
                    contribute to the vibrant local economy and enrich the
                    cultural experience for our guests. */}
        {/* </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section
          className="feature-area2 p-relative fix pt-5 mt-5 mb-5 pb-5"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img src={welcomeImg} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20"> */}
        {/* <h5>Sandalmist Resort & Ayurveda</h5> */}
        {/* <h2>Join Us for an Unforgettable Experience </h2>
                  </div>
                  <p> */}
        {/* Whether you’re seeking a romantic getaway, a peaceful
                    retreat, or an adventure-filled holiday, SandalMist is the
                    perfect destination. Immerse yourself in the natural beauty
                    and cultural richness of Kerala while enjoying the luxurious
                    comfort of our hilltop resort. We look forward to welcoming
                    you to SandalMist, where every stay is a cherished memory
                    waiting to be made.
                  </p> */}
        {/* <p>Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.</p> */}
        {/* <div className="slider-btn mt-15"> */}
        {/* <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				 */}
        {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="blog" className="blog-area p-relative fix pt-90 pb-90">
          <div className="animations-02">
            <img src={Blog} alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div
                  className="section-title center-align mb-50 text-center wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h5>Our Blog</h5>
                  <h2>Our Commitment to Excellence </h2>
                  <p>
                    At SandalMist, we are dedicated to providing our guests with
                    an exceptional experience. Our team is committed to offering
                    personalized service, ensuring that every aspect of your
                    stay is nothing short of perfect. From the moment you
                    arrive, you’ll be greeted with warm hospitality and a
                    genuine desire to make your stay memorable.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {blogsList.map((blog, index) => {
                return (
                  <>
                    <div className="col-lg-4 col-md-6">
                      <div
                        className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                      >
                        <div className="blog-thumb2">
                          <Link to={{ pathname: `/blog-details/${blog._id}` }}>
                            <img src={blog.blogImages[0]} alt="img" />
                          </Link>
                        </div>
                        <div className="blog-content2">
                          <div className="date-home">
                            {monthDateYear(blog.createdAt)}
                          </div>
                          <h3>
                            <Link
                              to={{ pathname: `/blog-details/${blog._id}` }}
                            >
                              {blog.title}
                            </Link>
                          </h3>
                          <p>
                            {blog.paragraph1.length > 150
                              ? blog.paragraph1.substring(0, 100) + "..."
                              : blog.paragraph1}
                          </p>
                          <div className="blog-btn">
                            <Link
                              to={{ pathname: `/blog-details/${blog._id}` }}
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section> */}

        <section
          className="newslater-area p-relative pt-60 pb-60"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-01">
            <img src="assets/img/bg/an-img-07.png" alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-xl-9 col-lg-9">
                <div
                  className="section-title center-align mb-40 text-center wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  {/* <h5>Newsletter</h5> */}
                  <h4>Contact Us</h4>
                  <p>
                    For reservations and more information, please get in touch
                    with us through our website or contact our friendly team. We
                    are here to assist you in planning your perfect escape to
                    our hilltop paradise.
                  </p>
                </div>
                <a className="text-center " href="/contact">
                  Click Here
                </a>
                {/* <form
                  name="ajax-form"
                  id="contact-form4"
                  action="#"
                  method="post"
                  className="contact-form newslater"
                > */}
                {/* <div className="form-group">
                    <input
                      className="form-control"
                      id="email3"
                      name="email"
                      type="email"
                      placeholder="Your Email Address"
                      value=""
                      required=""
                    />
                    <button type="submit" className="btn btn-custom" id="send2">
                      Subscribe Now
                    </button>
                  </div> */}
                {/* </form> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Main;
