import React from 'react';
import { Link } from 'react-router-dom';
import bekalFortImg from '../../assets/img/attractions/bekalfort.jpeg';
import kappilImg from '../../assets/img/attractions/kappil.jpg';
import chandragiriImg from '../../assets/img/attractions/Chandragiri.jpg';
import raniImg from '../../assets/img/attractions/Ranipuram.avif';
import madhurImg from '../../assets/img/attractions/madhur.jpg';
import ananthapuramImg from '../../assets/img/attractions/ananthapuram.jpg';
import mdhinaarImg from '../../assets/img/attractions/mdhinaar.jpg';
import karyangodImg from '../../assets/img/attractions/Karyangod.png';
import parappaImg from '../../assets/img/attractions/parappa.webp';
import caveryImg from '../../assets/img/attractions/cavery.jpg';
import nileswaramImg from '../../assets/img/attractions/nileswaram.jpg';
import keralaImg from "../../assets/img/bg/kerala_ban.jpg";
import BGImg from "../../assets/img/bg/attractions-ban.jpg";


const AttractionsNearby = () => {
  return (
    <>
    <section
    className="breadcrumb-area d-flex align-items-center"
    style={{
      background: `url(${BGImg}) no-repeat center center/cover`,
      
    }}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-12 col-lg-12">
          <div className="breadcrumb-wrap text-center">
            <div className="breadcrumb-title">
              <h2
                style={{
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  textShadow:
                    "",
                   
                    
                }}
              >
                Attractions Nearby
              </h2>
              <div className="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      id="top"
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Attractions Nearby
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    <div className="container-fluid p-0 mt-5 ">

      <div className="text-center  fade-in" style={{ position: 'relative', zIndex: 1 }}>
        
       
        <h2 className="display-6 fade-in justify-content-center text-center  pt-5 pb-5 " style={{ color: '#000000', fontSize:'30px' }}>Explore the scenic beauty and cultural heritage of Kasargod</h2>
      </div>

      <style jsx>{`
        .img-zoom-container {
          position: relative;
          overflow: hidden;
        }
        .img-zoom-container img {
          display: block;
          transition: transform 0.5s ease, filter 0.5s ease; // Modified transition for zoom and brightness
        }
        .img-zoom-container:hover img {
          transform: scale(1.2); // Adjust scale for zoom level
          filter: brightness(0.9); // Slightly reduce brightness on hover
        }
        .img-zoom-container img {
          width: 100%;
          height: auto;
        }
        .fade-in {
          animation: fadeIn 2s ease-in-out;
        }
        .fade-up {
          animation: fadeUp 2s ease-in-out;
        }
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        @keyframes fadeUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>

      <section className="p-5">
        <div className="row align-items-center fade-up">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-4 fade-up text-center">Bekal Fort</h3>
            <p className="fade-up" style={{  lineHeight: '1.6' }}>
              This impressive 17th-century fort is one of the largest and best-preserved forts in Kerala. It is renowned for its stunning architecture and scenic location overlooking the Arabian Sea. The fort features a large observation tower, a moat, and breathtaking views of the sea and surrounding landscape. It’s a great spot for history enthusiasts and photography.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <div className="img-zoom-container fade-up">
              <img src={bekalFortImg} alt="Bekal Fort" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-light">
        <div className="row align-items-center fade-up">
          <div className="col-md-7">
            <h3 className="mb-4 fade-up text-center">Kappil Beach</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              Kappil Beach is known for its serene atmosphere and golden sands. It’s a quieter alternative to some of the more crowded beaches in Kerala. Enjoy peaceful walks along the shore, explore the nearby Kappil River, or simply relax and take in the natural beauty of the area.
            </p>
          </div>
          <div className="col-md-5">
            <div className="img-zoom-container fade-up">
              <img src={kappilImg} alt="Kappil Beach" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="row align-items-center fade-up">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-4 fade-up text-center">Chandragiri Fort</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              Located on the banks of the Chandragiri River, this fort offers panoramic views of the river and the Arabian Sea. The fort, built in the 17th century, is known for its picturesque location and historical significance. It’s a great spot to experience the local history and enjoy the scenic views.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <div className="img-zoom-container fade-up">
              <img src={chandragiriImg} alt="Chandragiri Fort" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-light">
        <div className="row align-items-center fade-up">
          <div className="col-md-7">
            <h3 className="mb-4 fade-up text-center">Ranipuram</h3>
            <p className="fade-up" style={{  lineHeight: '1.6' }}>
              Ranipuram, often referred to as the “Ooty of Kerala,” is a charming hill station located in the Kasargod district of Kerala. Known for its lush green landscapes, rolling hills, and cool climate, Ranipuram is a serene destination that offers a refreshing escape from the hustle and bustle of city life. It’s a perfect spot for nature lovers, adventure enthusiasts, and anyone seeking tranquility amidst nature.
            </p>
          </div>
          <div className="col-md-5">
            <div className="img-zoom-container fade-up">
              <img src={raniImg} alt="Ranipuram" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="row align-items-center fade-up">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-4 fade-up text-center">Madhur Temple</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              This ancient temple dedicated to the Hindu deity Mahaganapathi is an important pilgrimage site in the region. The temple is known for its beautiful architecture, serene atmosphere, and significant religious importance. It’s a place to experience the local spiritual heritage.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <div className="img-zoom-container fade-up">
              <img src={madhurImg} alt="Madhur Temple" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-light">
        <div className="row align-items-center fade-up">
          <div className="col-md-7">
            <h3 className="mb-4 fade-up text-center">Ananthapura Lake Temple</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              Ananthapura Lake Temple is unique for being the only lake temple in Kerala. The temple is dedicated to Lord Vishnu and is surrounded by a scenic lake, creating a picturesque and serene environment. The temple is also known for its resident crocodile, believed to be the temple guardian. It’s a must-visit for its spiritual significance and natural beauty.
            </p>
          </div>
          <div className="col-md-5">
            <div className="img-zoom-container fade-up">
              <img src={ananthapuramImg} alt="Ananthapura Lake Temple" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="row align-items-center fade-up">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-4 fade-up text-center">Malik Deenar Mosque</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              One of the oldest mosques in India, Malik Deenar Mosque is a historic and religious landmark in Kasargod. The mosque is named after Malik Deenar, a follower of the Prophet Muhammad, and is an important site for the Muslim community. The mosque is known for its beautiful architecture and serene ambiance, making it a significant cultural and spiritual destination.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <div className="img-zoom-container fade-up">
              <img src={mdhinaarImg} alt="Malik Deenar Mosque" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-light">
        <div className="row align-items-center fade-up">
          <div className="col-md-7">
            <h3 className="mb-4 fade-up text-center">Karyangode River</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              The Karyangode River flows through the heart of Kasargod, offering scenic views and tranquil surroundings. It’s a great spot for picnics, nature walks, and enjoying the natural beauty of the region. The river is also known for its biodiversity, making it a wonderful destination for nature enthusiasts.
            </p>
          </div>
          <div className="col-md-5">
            <div className="img-zoom-container fade-up">
              <img src={karyangodImg} alt="Karyangode River" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="row align-items-center fade-up">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-4 fade-up text-center">Parappa Wildlife Sanctuary</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              This sanctuary is a haven for wildlife enthusiasts and nature lovers. It’s home to a diverse range of flora and fauna, including various species of birds, mammals, and reptiles. The sanctuary offers opportunities for wildlife spotting, nature walks, and experiencing the natural beauty of the region.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <div className="img-zoom-container fade-up">
              <img src={parappaImg} alt="Parappa Wildlife Sanctuary" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-light">
        <div className="row align-items-center fade-up">
          <div className="col-md-7">
            <h3 className="mb-4 fade-up text-center">Kaveri River</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              The Kaveri River is one of the major rivers in southern India, known for its scenic beauty and cultural significance. It flows through the heart of Kasargod, offering picturesque views and tranquil surroundings. The river is a popular spot for picnics, boating, and enjoying the natural beauty of the region.
            </p>
          </div>
          <div className="col-md-5">
            <div className="img-zoom-container fade-up">
              <img src={caveryImg} alt="Kaveri River" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="row align-items-center fade-up">
          <div className="col-md-7 order-md-2">
            <h3 className="mb-4 fade-up text-center">Nileswaram</h3>
            <p className="fade-up" style={{ lineHeight: '1.6' }}>
              Nileswaram is a historic town in Kasargod, known for its cultural heritage and traditional art forms. The town is home to several ancient temples, traditional houses, and cultural centers. It’s a great place to experience the local culture, art, and history of Kerala.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <div className="img-zoom-container fade-up">
              <img src={nileswaramImg} alt="Nileswaram" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

    </div>
    </>
  );
}

export default AttractionsNearby;
