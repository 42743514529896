import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import config from "../../services/config";
import Modal from "react-modal";
import RedirectWithOneTimeRefresh from "../ScrollToTop";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const [mobile, setMobile] = useState(false);
  const [showCottages, setShowCottages] = useState(true);
  const [cottagesList, setCottagesList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showLuxury, setShowLuxury] = useState(false);
  const [showPremium, setShowPremium] = useState(false);
  const [showDelux, setShowDelux] = useState(false);
  const [showMudhouse, setShowMudhouse] = useState(false);
  const [showTenthouse, setShowTenthouse] = useState(false);
  const [showTreehouse, setShowTreehouse] = useState(false);
  const [showSuitroom, setShowSuitroom] = useState(false);
  const [showDormitory, setShowDormitory] = useState(false);
  const [showCavehouse, setShowCavehouse] = useState(false);

  const [activities, setActivities] = useState([]);
  const [showActivities, setShowActivities] = useState(true);
  const [facilities, setFacilities] = useState([]);
  const [showFacilities, setShowFacilities] = useState(false);

  const [activeMenu, setActiveMenu] = useState("sandalMist"); // Open SandalMist by default
  const [showGallery, setShowGallery] = useState(false); // Initialize the Gallery submenu as closed

  const location = useLocation();
  const path = location.pathname;
  const { id } = useParams(); // Added useParams for accessing route parameters
  const navigate = useNavigate();

  const handleLinkClickk = (facilityId) => {
    setShowFacilities(false); // Optionally close the submenu
    navigate(`/facilities/${facilityId}`);
  };

  const dispatch = useDispatch();
  const allFacilities = useSelector((state) => state.facilitiesData); // Added useSelector for accessing facilities data from Redux
  const mudHouseCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Mud house" && cottage.title1
  );
  const tentHouseCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Tent house" && cottage.title1
  );
  const caveHouseCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Cave house" && cottage.title1
  );
  const suitRoomCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Suit room" && cottage.title1
  );
  const DormitoryCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Dormitory" && cottage.title1
  );
  const TreeHouseCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Tree house" && cottage.title1
  );
  const DeluxCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Delux" && cottage.title1
  );
  const PremiumCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Premium" && cottage.title1
  );
  const LuxuryCottages = cottagesList.filter(
    (cottage) => cottage.cottageType === "Luxury" && cottage.title1
  );

  useEffect(() => {
    getAllCottages(); // Fetch cottages data on component mount
    getAllFacilities(); // Fetch facilities data on component mount
    getAllActivities(); // Fetch activities data on component mount
  }, []);

  const getAllCottages = () => {
    axios
      .get(`${config.baseUrl}getAllCottages`)
      .then((res) => {
        if (res.status === 200) {
          setCottagesList(res.data.data);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  const getAllActivities = () => {
    axios
      .get(`${config.baseUrl}getAllActivities`)
      .then((res) => {
        if (res.status === 200) {
          setActivities(res.data.data);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  const getAllFacilities = () => {
    axios
      .get(`${config.baseUrl}getAllFacilities`)
      .then((res) => {
        if (res.status === 200) {
          setFacilities(res.data.data);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  if (allFacilities?.facilitiesList?.data) {
    const exactFacility = allFacilities.facilitiesList.data.find(
      (facility) => facility._id === id
    );

    // Additional component logic here
  }

  const handleLinkClick = () => {
    console.log("Link clicked, setting mobile to false");
    setMobile(false);
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const toggleLuxuryMenu = () => {
    setShowLuxury(!showLuxury);
  };
  const toggleDeluxMenu = () => {
    setShowDelux(!showDelux);
  };
  const toggleCavehouseMenu = () => {
    setShowCavehouse(!showCavehouse);
  };
  const toggleTenthouseMenu = () => {
    setShowTenthouse(!showTenthouse);
  };
  const toggleTreehouseMenu = () => {
    setShowTreehouse(!showTreehouse);
  };
  const toggleMudhouseMenu = () => {
    setShowMudhouse(!showMudhouse);
  };
  const toggleSuitroomMenu = () => {
    setShowSuitroom(!showSuitroom);
  };
  const toggleDormitoryMenu = () => {
    setShowDormitory(!showDormitory);
  };
  const togglePremiumMenu = () => {
    setShowPremium(!showPremium);
  };

  const customStyles = {
    content: {
      top: "40%",
      left: "20%",
      right: "20%",
      bottom: "auto",
    },
  };

  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 d-none  d-lg-block">
                <div className="header-cta">
                  <ul>
                    <li>
                      <i className="far fa-envelope"></i>
                      <span>Info@sandalmistresort.com</span>
                    </li>
                    <li>
                      <i className="far fa-mobile"></i>
                      <strong>+91-9778434442 , +91-9778474442</strong>
                    </li>
                    <li>
                      <div className="contact-field p-relative c-name mb-20">
                        <a
                          href="https://api.whatsapp.com/send?phone=9778434442"
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fab fa-whatsapp"
                            style={{ color: "white", fontSize: "20px" }} // Adjust the fontSize value as needed
                          ></i>

                          <strong>+91 9778434442</strong>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 d-none d-lg-block text-right">
                <div className="header-social">
                  <span mb-4>
                    <a
                      href="https://www.facebook.com/sandalmistresortSandalMistresortsandspa/"
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/sandalmist_resort_and_spa/"
                      title="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://x.com/sandalmist"
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@SandalmistResortsandSpa"
                      title="YouTube"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customStyles}
          contentLabel="Book a Room"
        >
          <div className="col-md-12">
            <div className="sidebar-widget categories">
              <div className="widget-content">
                <h2 className="widget-title" style={{ color: "#85ab00" }}>
                  Book A Room
                </h2>
                <div className="contact-field p-relative c-name mb-20">
                  <label className="mb-0">
                    <i className="fal fa-phone"></i> Call us
                  </label>
                  <a href="tel:9061831733" style={{ cursor: "pointer" }}>
                    <h4>9778434442</h4>
                  </a>
                </div>
                <div className="contact-field p-relative c-name mb-20">
                  <label className="mb-0">
                    <i className="fal fa-envelope"></i> Mail us
                  </label>
                  <a
                    href="mailto:info@sandalmistresort.com?subject=Cottage Price Enquiry&body=Hello!"
                    style={{ cursor: "pointer" }}
                  >
                    <h4>info@sandalmistresort.com</h4>
                  </a>
                </div>
                <div className="contact-field p-relative c-name mb-20">
                  <label className="mb-0">
                    <i
                      className="fab fa-whatsapp"
                      style={{ color: "green" }}
                    ></i>{" "}
                    Text us on WhatsApp
                  </label>
                  <a
                    href="https://api.whatsapp.com/send?phone=9778434442"
                    style={{ cursor: "pointer" }}
                  >
                    <h4>9778434442</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setOpenModal(false)}
            className="btn btn-secondary"
          >
            Close
          </button>
        </Modal>

        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="../../assets/img/logo/SandalMist_logo.svg"
                        alt="logo"
                        style={{
                          filter: "brightness(1.5)",
                          maxWidth: "100%",
                          height: "auto",
                          width: "170px",
                          padding: "10px",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8">
                  <nav id="mobile-menu" className="main-menu text-center">
                    <ul>
                      <li className="has-sub">
                        <Link to="/" onClick={handleLinkClick}>
                          Home
                        </Link>
                      </li>
                      <li className="has-sub">
                        <Link to="#" onClick={handleLinkClick}>
                          SandalMist
                        </Link>
                        <ul>
                          <li>
                            <Link to="/about" onClick={handleLinkClick}>
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog" onClick={handleLinkClick}>
                              News
                            </Link>
                          </li>
                          <li className="has-sub">
                            <Link to="" onClick={handleLinkClick}>
                              Gallery
                            </Link>
                            <ul>
                              <li>
                                <Link to="/gallery" onClick={handleLinkClick}>
                                  image Gallery
                                </Link>
                              </li>
                              {/* <li>
                                <Link
                                  to="/video-gallery"
                                  onClick={handleLinkClick}
                                >
                                  Video Gallery
                                </Link>
                              </li> */}
                            </ul>
                          </li>
                          <li className="has-sub">
                            <Link to="/testimonial" onClick={handleLinkClick}>
                              Testimonial
                            </Link>
                          </li>
                          <li>
                            <Link to="/attraction" onClick={handleLinkClick}>
                              Attraction Nearby
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="has-sub">
                        <Link to="/facilities" onClick={handleLinkClick}>
                          Facilities
                        </Link>
                      </li> */}
                      {/* <li className="has-sub">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => setShowFacilities(!showFacilities)} // Toggle submenu on click
                            >
                              <Link
                                to="#"
                                onClick={(e) => e.preventDefault()} // Prevent default link behavior
                              >
                                Facilities
                              </Link>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setShowFacilities(!showFacilities)
                                } // Toggle submenu on button click
                              >
                                
                              </button>
                            </div>

                            <ul
                              style={{
                                display: showFacilities ? "block" : "none",
                              }}
                            >
                              {facilities.map((facility) => (
                                <li key={facility._id}>
                                  <Link
                                    to={`/facilities#${facility.title.replace(/\s+/g, '')}`}
                                    onClick={handleLinkClick}
                                  >
                                    {facility.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li> */}
                      {/* <li className="has-sub">
                        <Link
                          to=""
                          onClick={() => setShowFacilities(!showFacilities)}
                        >
                          Facilities
                        </Link>
                        <ul
                          style={{ display: showFacilities ? "block" : "none" }}
                        >
                          {facilities.map((facility) => (
                            <li key={facility._id}>
                              <Link
                                to={`/facilities/${facility._id}`}
                                // Remove handleLinkClick if it's not necessary
                              >
                                {facility.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li> */}

                      <li className="has-sub">
                        <Link
                          to="#"
                          onClick={() => setShowActivities(!showActivities)}
                        >
                          Activities
                        </Link>
                        <ul
                          style={{ display: showActivities ? "block" : "none" }}
                        >
                          {activities.map((activity) => (
                            <li key={activity._id}>
                              <Link
                                to={`/activity-details/${activity._id}`}
                                onClick={() => {
                                  handleLinkClick(); // Call the existing handler
                                  setShowActivities(false); // Close the dropdown
                                }}
                              >
                                {activity.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li className="has-sub">
                        <Link
                          onClick={() => setShowFacilities(!showFacilities)}
                        >
                          Facilities
                        </Link>
                        <ul
                          style={{ display: showFacilities ? "block" : "none" }}
                        >
                          {facilities.map((facility) => (
                            <li key={facility._id}>
                              <Link
                                to={`/facilities/${facility._id}`}
                                onClick={handleLinkClickk}
                              >
                                {facility.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li className="has-sub">
                        <Link
                          to="#"
                          onClick={() => setShowCottages(!showCottages)}
                        >
                          Cottages
                        </Link>
                        <ul
                          style={{ display: showCottages ? "block" : "none" }}
                        >
                          {LuxuryCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleLuxuryMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Luxury
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleLuxuryMenu} // Toggle submenu on button click
                                >
                                  {showLuxury ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showLuxury ? "block" : "none",
                                }}
                              >
                                {LuxuryCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                          {PremiumCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: 0,
                                  alignItems: "center",
                                }}
                                onClick={togglePremiumMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Premium
                                </Link>
                                
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={togglePremiumMenu} // Toggle submenu on button click
                                >
                                  {showPremium ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showPremium ? "block" : "none",
                                }}
                              >
                                {PremiumCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                          {DeluxCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleDeluxMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Delux
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleDeluxMenu} // Toggle submenu on button click
                                >
                                  {showDelux ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showDelux ? "block" : "none",
                                }}
                              >
                                {DeluxCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                          {TreeHouseCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleTreehouseMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Tree House
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleTreehouseMenu} // Toggle submenu on button click
                                >
                                  {showTreehouse ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showTreehouse ? "block" : "none",
                                }}
                              >
                                {TreeHouseCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}

                          {/* edited mudhouse */}

                          {mudHouseCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleMudhouseMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Mud House
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleMudhouseMenu} // Toggle submenu on button click
                                >
                                  {showMudhouse ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showMudhouse ? "block" : "none",
                                }}
                              >
                                {mudHouseCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}

                          {tentHouseCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleTenthouseMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Tent House
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleTenthouseMenu} // Toggle submenu on button click
                                >
                                  {showTenthouse ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showTenthouse ? "block" : "none",
                                }}
                              >
                                {tentHouseCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                          {caveHouseCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleCavehouseMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Cave House
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleCavehouseMenu} // Toggle submenu on button click
                                >
                                  {showCavehouse ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showCavehouse ? "block" : "none",
                                }}
                              >
                                {caveHouseCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                          {suitRoomCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleSuitroomMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Suit Room
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleSuitroomMenu} // Toggle submenu on button click
                                >
                                  {showSuitroom ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showSuitroom ? "block" : "none",
                                }}
                              >
                                {suitRoomCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                          {DormitoryCottages.length > 0 && (
                            <li className="has-sub">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={toggleDormitoryMenu} // Toggle submenu on click
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Dormitory
                                </Link>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleDormitoryMenu} // Toggle submenu on button click
                                >
                                  {showDormitory ? "-" : "+"}
                                </button>
                              </div>
                              <ul
                                style={{
                                  display: showDormitory ? "block" : "none",
                                }}
                              >
                                {DormitoryCottages.map((filteredCottage) => (
                                  <li key={filteredCottage._id}>
                                    <Link
                                      to={`/room-details/${filteredCottage._id}`}
                                      onClick={handleLinkClick}
                                    >
                                      {filteredCottage.title1}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact" onClick={handleLinkClick}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                  <Link
                    onClick={() => setOpenModal(true)}
                    className="top-btn mt-10 mb-10"
                    style={{ background: "#85ab00" }}
                  >
                    Book Now
                  </Link>
                </div>

                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <Link
                        to="#nav"
                        className={`meanmenu-reveal ${
                          mobile ? "meanclose" : ""
                        }`}
                        onClick={() => {
                          console.log("Toggling mobile menu", mobile);
                          setMobile(!mobile);
                        }}
                        style={{
                          right: "0px",
                          left: "auto",
                          textAlign: "center",
                          textIndent: "0px",
                          fontSize: "18px",
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <span>
                            <span>
                              <span></span>
                            </span>
                          </span>
                        )}
                      </Link>

                      <nav
                        className={`mean-nav ${mobile ? "show" : ""}`}
                        style={{
                          display: mobile ? "block" : "none", // Show or hide based on the `mobile` state
                          // Add any other styles you want to apply here
                        }}
                      >
                        <ul>
                          <li className="has-sub">
                            <Link to="/" onClick={handleLinkClick}>
                              Home
                            </Link>
                          </li>
                          <li className="has-sub">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => handleMenuClick("sandalMist")} // Toggle submenu on click
                            >
                              <Link to="#" onClick={(e) => e.preventDefault()}>
                                SandalMist
                              </Link>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleMenuClick("sandalMist")} // Toggle submenu on button click
                              >
                                {activeMenu === "sandalMist" ? "-" : "+"}
                              </button>
                            </div>
                            {activeMenu === "sandalMist" && (
                              <ul>
                                <li>
                                  <Link to="/about" onClick={handleLinkClick}>
                                    About Us
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blog" onClick={handleLinkClick}>
                                    News
                                  </Link>
                                </li>
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={() => setShowGallery(!showGallery)} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Gallery
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setShowGallery(!showGallery)
                                      } // Toggle submenu on button click
                                    >
                                      {showGallery ? "-" : "+"}
                                    </button>
                                  </div>

                                  <ul
                                    style={{
                                      display: showGallery ? "block" : "none",
                                    }}
                                  >
                                    <li>
                                      <Link
                                        to="/gallery"
                                        onClick={handleLinkClick}
                                      >
                                        Image Gallery
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link
                                        to="/video-gallery"
                                        onClick={handleLinkClick}
                                      >
                                        Video Gallery
                                      </Link>
                                    </li> */}
                                  </ul>
                                </li>
                                <li className="has-sub">
                                  <Link
                                    to="/testimonial"
                                    onClick={handleLinkClick}
                                  >
                                    Testimonial
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/attraction"
                                    onClick={handleLinkClick}
                                  >
                                    Attraction Nearby
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </li>

                          {/* <li className="has-sub">
                            <Link to="/facilities" onClick={handleLinkClick}>
                              Facilities
                            </Link>
                          </li> */}
                          <li className="has-sub">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => setShowFacilities(!showFacilities)} // Toggle submenu on click
                            >
                              <Link
                                to="#"
                                onClick={(e) => e.preventDefault()} // Prevent default link behavior
                              >
                                Facilities
                              </Link>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setShowFacilities(!showFacilities)
                                } // Toggle submenu on button click
                              >
                                {showFacilities ? "-" : "+"}
                              </button>
                            </div>

                            <ul
                              style={{
                                display: showFacilities ? "block" : "none",
                              }}
                            >
                              {facilities.map((facility) => (
                                <li key={facility._id}>
                                  <Link
                                    to={`/facilities/${facility._id}`}
                                    onClick={handleLinkClick}
                                  >
                                    {facility.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li className="has-sub">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => setShowActivities(!showActivities)} // Toggle submenu on click
                            >
                              <Link
                                to="#"
                                onClick={(e) => e.preventDefault()} // Prevent default link behavior
                              >
                                Activities
                              </Link>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setShowActivities(!showActivities)
                                } // Toggle submenu on button click
                              >
                                {showActivities ? "-" : "+"}
                              </button>
                            </div>

                            <ul
                              style={{
                                display: showActivities ? "block" : "none",
                              }}
                            >
                              {activities.map((activity) => (
                                <li key={activity._id}>
                                  <Link
                                    to={`/activity-details/${activity._id}`}
                                    onClick={handleLinkClick}
                                  >
                                    {activity.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>

                          <li className="has-sub">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => setShowCottages(!showCottages)} // Toggle submenu on click
                            >
                              <Link to="#" onClick={(e) => e.preventDefault()}>
                                Cottages
                              </Link>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowCottages(!showCottages)} // Toggle submenu on button click
                              >
                                {showCottages ? "-" : "+"}
                              </button>
                            </div>

                            <ul
                              style={{
                                display: showCottages ? "block" : "none",
                              }}
                            >
                              {LuxuryCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleLuxuryMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Luxury
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleLuxuryMenu} // Toggle submenu on button click
                                    >
                                      {showLuxury ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showLuxury ? "block" : "none",
                                    }}
                                  >
                                    {LuxuryCottages.map((filteredCottage) => (
                                      <li key={filteredCottage._id}>
                                        <Link
                                          to={`/room-details/${filteredCottage._id}`}
                                          onClick={handleLinkClick}
                                        >
                                          {filteredCottage.title1}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                              {PremiumCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={togglePremiumMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Premium
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={togglePremiumMenu} // Toggle submenu on button click
                                    >
                                      {showPremium ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showPremium ? "block" : "none",
                                    }}
                                  >
                                    {PremiumCottages.map((filteredCottage) => (
                                      <li key={filteredCottage._id}>
                                        <Link
                                          to={`/room-details/${filteredCottage._id}`}
                                          onClick={handleLinkClick}
                                        >
                                          {filteredCottage.title1}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                              {DeluxCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleDeluxMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Delux
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleDeluxMenu} // Toggle submenu on button click
                                    >
                                      {showDelux ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showDelux ? "block" : "none",
                                    }}
                                  >
                                    {DeluxCottages.map((filteredCottage) => (
                                      <li key={filteredCottage._id}>
                                        <Link
                                          to={`/room-details/${filteredCottage._id}`}
                                          onClick={handleLinkClick}
                                        >
                                          {filteredCottage.title1}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                              {TreeHouseCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleTreehouseMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Tree House
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleTreehouseMenu} // Toggle submenu on button click
                                    >
                                      {showTreehouse ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showTreehouse ? "block" : "none",
                                    }}
                                  >
                                    {TreeHouseCottages.map(
                                      (filteredCottage) => (
                                        <li key={filteredCottage._id}>
                                          <Link
                                            to={`/room-details/${filteredCottage._id}`}
                                            onClick={handleLinkClick}
                                          >
                                            {filteredCottage.title1}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )}
                              {mudHouseCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleMudhouseMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Mud House
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleMudhouseMenu} // Toggle submenu on button click
                                    >
                                      {showMudhouse ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showMudhouse ? "block" : "none",
                                    }}
                                  >
                                    {mudHouseCottages.map((filteredCottage) => (
                                      <li key={filteredCottage._id}>
                                        <Link
                                          to={`/room-details/${filteredCottage._id}`}
                                          onClick={handleLinkClick}
                                        >
                                          {filteredCottage.title1}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                              {tentHouseCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleTenthouseMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Tent House
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleTenthouseMenu} // Toggle submenu on button click
                                    >
                                      {showTenthouse ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showTenthouse ? "block" : "none",
                                    }}
                                  >
                                    {tentHouseCottages.map(
                                      (filteredCottage) => (
                                        <li key={filteredCottage._id}>
                                          <Link
                                            to={`/room-details/${filteredCottage._id}`}
                                            onClick={handleLinkClick}
                                          >
                                            {filteredCottage.title1}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )}
                              {caveHouseCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleCavehouseMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Cave House
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleCavehouseMenu} // Toggle submenu on button click
                                    >
                                      {showCavehouse ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showCavehouse ? "block" : "none",
                                    }}
                                  >
                                    {caveHouseCottages.map(
                                      (filteredCottage) => (
                                        <li key={filteredCottage._id}>
                                          <Link
                                            to={`/room-details/${filteredCottage._id}`}
                                            onClick={handleLinkClick}
                                          >
                                            {filteredCottage.title1}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )}
                              {suitRoomCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleSuitroomMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Suit Room
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleSuitroomMenu} // Toggle submenu on button click
                                    >
                                      {showSuitroom ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showSuitroom ? "block" : "none",
                                    }}
                                  >
                                    {suitRoomCottages.map((filteredCottage) => (
                                      <li key={filteredCottage._id}>
                                        <Link
                                          to={`/room-details/${filteredCottage._id}`}
                                          onClick={handleLinkClick}
                                        >
                                          {filteredCottage.title1}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                              {DormitoryCottages.length > 0 && (
                                <li className="has-sub">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={toggleDormitoryMenu} // Toggle submenu on click
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Dormitory
                                    </Link>
                                    <button
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggleDormitoryMenu} // Toggle submenu on button click
                                    >
                                      {showDormitory ? "-" : "+"}
                                    </button>
                                  </div>
                                  <ul
                                    style={{
                                      display: showDormitory ? "block" : "none",
                                    }}
                                  >
                                    {DormitoryCottages.map(
                                      (filteredCottage) => (
                                        <li key={filteredCottage._id}>
                                          <Link
                                            to={`/room-details/${filteredCottage._id}#head`}
                                            onClick={handleLinkClick}
                                          >
                                            {filteredCottage.title1}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )}
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact" onClick={handleLinkClick}>
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="mean-push"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
