const initialState={
    cottagesList:[],
    loading:false,
    error:false
}

function CottagesReducer(state = initialState, action){
    switch(action.type){
        case'LOAD_COTTAGES':
            return {...state, cottagesList:[], error:false, loading:true };
        case 'GET_COTTAGES':

        return {...state, cottagesList:action.payload, error:false, loading:false}
        case 'ERROR_COTTAGES':
            return {...state, cottagesList:[], error:true, loading:false}

    default:
            return state;
    }
}

export default CottagesReducer;