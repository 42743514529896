import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import config from '../../../services/config';
import ActivitiesAction from '../../../store/actions/ActivitiesAction';
import Loader from "../../loader";

const ActivityDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [blogsList, setBlogsList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await ActivitiesAction.loadActivities(dispatch);
                await getRecentBlogsAPI();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        window.scrollTo(0, 0);
    }, [dispatch]);

    const getRecentBlogsAPI = async () => {
        try {
            const res = await axios.get(config.baseUrl + "getRecentBlogs");
            if (res.status === 200) {
                setBlogsList(res.data.data);
            } else {
                console.log(res.data.message);
            }
        } catch (err) {
            console.log(err.toString());
        }
    };

    const allActivities = useSelector((state) => state.activitiesData);
    const exactActivity = allActivities.activitiesList.data?.data.find((activity) => activity._id === id);

    if (loading) {
        return <Loader />;
    }

    if (!exactActivity) {
        return <p>Activity not found</p>;
    }

    return (
        <main>
            <section 
                className="breadcrumb-area d-flex align-items-center"
                style={{
                    background: `url(${exactActivity.activityBannerImage ?? 'https://basilresort.s3.ap-south-1.amazonaws.com/ActivityBannerImage/ActivityBannerImage/-638ec5792aff9504f0622222'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    color: '#fff',
                    padding: '40px 0'
                }}
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12 text-center breadcrumb-wrap">
                            <div className="breadcrumb-title" style={{ position: 'relative', zIndex: 2 }}>
                                <h2
                                    style={{
                                        fontSize: '3rem',
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                        color: 'whitesmoke',
                                        // textShadow: '2px 2px 0px #85ab00, -2px -2px 0px #85ab00, 2px -2px 0px #85ab00, -2px 2px 0px #85ab00'
                                    }}
                                >
                                    {exactActivity.title}
                                </h2>
                                <div className="breadcrumb-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb" style={{ marginBottom: 0 }}>
                                            <li className="breadcrumb-item"><Link to="/" style={{ color: '#85ab00' }}>Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{exactActivity.title} Details</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-90">
                    <div className="row" style={{ display: 'flex' }}>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ paddingRight: '15px' }}>
                            <div className="service-detail">
                                <div className="content-box" style={{
                                    backgroundColor: '#f9f9f9',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                                }}>
                                    <h2 style={{ color: '#333', marginBottom: '15px' }}>{exactActivity.title}</h2>
                                    <p style={{ color: '#555', lineHeight: '1.6' }}>{exactActivity.paragraph1}</p>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    {exactActivity.thumbnailImage && exactActivity.thumbnailImage.length > 0 && (
                                                        <img 
                                                            src={exactActivity.thumbnailImage[0]} 
                                                            alt="" 
                                                            style={{
                                                                border: 'none',
                                                                borderRadius: '8px',
                                                                maxWidth: '100%',
                                                                height: 'auto'
                                                            }} 
                                                        />
                                                    )}
                                                </figure>
                                            </div>
                                            {exactActivity.thumbnailImage && exactActivity.thumbnailImage.length > 1 && (
                                                <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                    <figure className="image">
                                                        <img 
                                                            src={exactActivity.thumbnailImage[1]} 
                                                            alt="" 
                                                            style={{
                                                                border: 'none',
                                                                borderRadius: '8px',
                                                                maxWidth: '100%',
                                                                height: 'auto'
                                                            }} 
                                                        />
                                                    </figure>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <h3 style={{ color: '#333', marginBottom: '15px' }}>{exactActivity.header1}</h3>
                                    <p style={{ color: '#555', lineHeight: '1.6' }}>{exactActivity.paragraph2}</p>
                                    <div className="two-column">
                                        <div className="row">
                                            {exactActivity.thumbnailImage && exactActivity.thumbnailImage.length > 2 && (
                                                <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                    <figure className="image">
                                                        <img 
                                                            src={exactActivity.thumbnailImage[2]} 
                                                            alt="" 
                                                            style={{
                                                                border: 'none',
                                                                borderRadius: '8px',
                                                                maxWidth: '100%',
                                                                height: 'auto'
                                                            }} 
                                                        />
                                                    </figure>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <h3 style={{ color: '#333', marginBottom: '15px' }}>{exactActivity.header2}</h3>
                                    <p style={{ color: '#555', lineHeight: '1.6' }}>{exactActivity.paragraph3}</p>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    <img 
                                                        src={exactActivity.activityDetailsImage} 
                                                        alt="" 
                                                        style={{
                                                            border: 'none',
                                                            borderRadius: '8px',
                                                            maxWidth: '100%',
                                                            height: 'auto'
                                                        }} 
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                       
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ActivityDetails;
