import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../services/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BGImg from "../../assets/img/bg/pexels-contact.jpg";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  
  const location = useLocation();
  const [hasReloaded, setHasReloaded] = useState(false);

  // useEffect(() => {
  //   // Use sessionStorage to manage one-time reload
  //   const reloadFlag = sessionStorage.getItem("contactPageReloaded");

  //   if (!reloadFlag) {
  //     sessionStorage.setItem("contactPageReloaded", "true");
  //     window.location.replace(window.location.href);
  //   } else {
  //     sessionStorage.removeItem("contactPageReloaded");
  //   }
  // }, [location.pathname]);

  const submitContact = (e) => {
    e.preventDefault();
    const contactsObj = { name, email, phone, subject, message };
    console.log("contactsObj: ", contactsObj);
    createContact(contactsObj);
  };

  const createContactToast = () => toast.success("Message sent successfully");

  function createContact(contactsObj) {
    axios({
      url: config.baseUrl + "createContact",
      data: contactsObj,
      method: "POST",
    }).then((res) => {
      if (res.status === 200) {
        createContactToast();
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      }
    });
  }

  return (
    <>
      <main>
        <section id="head"
          className="breadcrumb-area d-flex align-items-center"
          style={{
            background: `url(${BGImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            
          }}
        >
          <div className="container">
            <ToastContainer />
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2
                      
                      style={{
                        fontSize: '3rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Contact Us
                    </h2>
                    <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Contact
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4 order-1">
                <div className="contact-info">
                  <div
                    className="single-cta pb-30 mb-30 wow fadeInUp animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                    style={{ textAlign: "left" }}
                  >
                    <div
                      className="cta-content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div className="f-cta-icon" style={{ marginRight: "15px" }}>
                        <i className="far fa-map" style={{ fontSize: "24px" }}></i>
                      </div>
                      <h5 style={{ margin: 0 }}>Address</h5>
                    </div>
                    <p style={{ marginLeft: "39px" }}>
                      SANDALMIST RESORT & SPA </p>
                      <p style={{ marginLeft: "39px" }}>
                      12/374, Bedadukka Panchayath</p>
                      <p style={{ marginLeft: "39px" }}>
                      Munnad, Kasaragod Kerala </p>
                      <p style={{ marginLeft: "39px" }}>
                      Pin: 671541 </p>
                      <p style={{ marginLeft: "39px" }}>
                      Mob: 9778434442, 9778474442
                    </p>
                  </div>

                  <div
                    className="single-cta wow fadeInUp animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                    style={{ textAlign: "left" }}
                  >
                    <div
                      className="cta-content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div className="f-cta-icon" style={{ marginRight: "15px" }}>
                        <i className="far fa-envelope-open" style={{ fontSize: "24px" }}></i>
                      </div>
                      <h5 style={{ margin: 0 }}>Message Us</h5>
                    </div>
                    <p style={{ marginLeft: "39px" }}  >
                      <Link to="#">Info@sandalmistresort.com</Link>
                    </p>  <p style={{ marginLeft: "39px" }}  >
                      <Link to="#">feedback@sandalmistresort.com</Link>
                    </p>  <p style={{ marginLeft: "39px" }}  >
                      <Link to="#">hr@sandalmistresort.com</Link>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 order-2">
                <div className="contact-bg02">
                  <div
                    className="section-title center-align mb-40 text-center wow fadeInDown animated"
                    data-animation="fadeInDown"
                    data-delay=".4s"
                  >
                    <h2>Get In Touch</h2>
                  </div>
                  <form className="contact-forms mt-30">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="firstn"
                            placeholder="Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                            type="text"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                            type="number"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone No."
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                            type="text"
                            name="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-message mb-30">
                          <textarea
                            type="text"
                            name="message"
                            cols="30"
                            rows="10"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Write comments"
                          ></textarea>
                        </div>
                      </div>
                      <div className="slider-btn">
                        <button
                          onClick={submitContact}
                          className="btn "
                          type="submit"
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="animations-01">
            <img src="assets/img/bg/an-img-07.png" alt=">>>" />
          </div>
          <div className="animations-02">
            <img src="assets/img/bg/an-img-08.png" alt="..." />
          </div>
          <div className="animations-03">
            <img src="assets/img/bg/an-img-09.png" alt="..." />
          </div>
          <div className="animations-04">
            <img src="assets/img/bg/an-img-10.png" alt="..." />
          </div> */}
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div
                  className="section-title text-left mb-30 wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h2>Subscribe To Our Newsletter</h2>
                </div>
              </div>
              <div className="col-lg-10">
                <form
                  className="news-letter-form wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".8s"
                >
                  <input
                    type="text"
                    placeholder="Enter your email address"
                    required
                  />
                  <button type="submit" className="btn">
                    <span>Subscribe</span>
                  </button>
                </form>
              </div>
            </div>
          </div> */}
        </section>
      </main>
    </>
  );
};

export default Contact;
