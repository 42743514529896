import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlogsAction from '../../../store/actions/BlogsAction';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../../services/config';
import Loader from "../../loader";

const BlogDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [blogsList, setBlogsList] = useState([]);
    

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        BlogsAction.loadBlogs(dispatch);
        getAllBlogsAPI();
    }, [dispatch]);

    function getAllBlogsAPI() {
        axios({
            url: config.baseUrl + "getAllBlogs",
            method: "GET",
        }).then((res) => {
            if (res.status === 200) {
                setBlogsList(res.data.data);
            } else if (res.status === 201) {
                console.log(res.data.message);
            }
        }).catch((err) => { console.log(err.toString()); });
    }

    function monthDateYear(dates) {
        const date = new Date(dates);
        return `${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const submitContact = (e) => {
        e.preventDefault();
        const contactsObj = { name, email, phone, subject, message };
        createContact(contactsObj);
    }

    const createContactToast = () => toast.success("Message sent successfully");

    function createContact(contactsObj) {
        axios({
            url: config.baseUrl + "createContact",
            data: contactsObj,
            method: 'POST',
        }).then((res) => {
            if (res.status === 200) {
                createContactToast();
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
            }
        });
    }

    const allBlogs = useSelector((state) => state.blogsData);
    if (allBlogs.blogsList.data !== undefined) {
        const exactBlog = allBlogs.blogsList.data.data.find((blog) => blog._id === id);
        
        const breadcrumbStyle = {
            backgroundImage: `url(${exactBlog.blogBanner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '50px 0',
            color: 'white'
        };

        const containerStyle = {
            padding: '20px',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        };

        const headingStyle = {
            fontSize: '2rem',
            marginBottom: '20px'
        };

        const metaInfoStyle = {
            fontSize: '0.9rem',
            color: '#6c757d'
        };

        const imgStyle = {
            width: '100%',
            borderRadius: '8px'
        };

        const formStyle = {
            background: '#f9f9f9',
            padding: '50px 0'
        };

        const formWrapStyle = {
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        };

        const textareaStyle = {
            width: '100%',
            height: '150px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '10px',
            resize: 'vertical'
        };

        const btnStyle = {
            backgroundColor: '#85ab00',
            border: 'none',
            color: '#fff',
            padding: '10px 20px',
            fontSize: '1rem',
            borderRadius: '8px',
            cursor: 'pointer'
        };
        

        return (
            <>
                <main>
                    <div className="modal fade bs-example-modal-lg search-bg popup1" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content search-popup">
                                <div className="text-center">
                                    <Link to="#" className="close2" data-dismiss="modal" aria-label="Close">× Close</Link>
                                </div>
                                <div className="row search-outer">
                                    <div className="col-md-11">
                                        <input type="text" placeholder="Search for products..." className="form-control" />
                                    </div>
                                    <div className="col-md-1 text-right">
                                        <Link to="#"><i className="fa fa-search" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="breadcrumb-area d-flex align-items-center" style={breadcrumbStyle}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-center">
                                        <div className="breadcrumb-title">
                                            <h2 style={{ fontSize: '2.5rem', fontWeight: '700' }}>{exactBlog.title}</h2>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to="/" style={{ color: 'white' }}>Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page" style={{ color: 'white' }}>News Details</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="inner-blog b-details-p pt-120 pb-120">
                        <div className="container" style={containerStyle}>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="blog-details-wrap">
                                        <div className="details__content pb-30">
                                            <h2 style={headingStyle}>{exactBlog.mainHeader}</h2>
                                            <div className="meta-info">
                                                <ul>
                                                    <li style={metaInfoStyle}><i className="fal fa-calendar-alt"></i> {monthDateYear(exactBlog.createdAt)}</li>
                                                </ul>
                                            </div>
                                            <p>{exactBlog.paragraph1}</p>
                                            <div className="details__content-img mb-4">
                                                <img src={exactBlog.blogImages[1]} alt="" style={imgStyle} />
                                            </div>
                                            <p>{exactBlog.paragraph2}</p>
                                            {exactBlog.quote &&
                                                <blockquote className="blockquote">
                                                    <p>{exactBlog.quote}</p>
                                                </blockquote>}
                                            <figure>
                                                <img src={exactBlog.smallImage} alt="" style={imgStyle} />
                                                <figcaption>{exactBlog.paragraph3}</figcaption>
                                            </figure>
                                            <p>{exactBlog.paragraph4}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <aside className="sidebar-widget">
                                        <section className="widget widget_social mb-45">
                                            <h2 className="widget-title" style={{ fontSize: '1.5rem', fontWeight: '700' }}>Follow Us</h2>
                                            <div className="widget-social">
                                                <a href="https://x.com/sandalmist" className="social-icon"><i className="fab fa-twitter"></i></a>
                                                <a href="https://www.youtube.com/@SandalmistResortsandSpa" className="social-icon"><i className="fab fa-youtube"></i></a>
                                                <a href="https://www.facebook.com/sandalmistresortSandalMistresortsandspa/" className="social-icon"><i className="fab fa-facebook-f"></i></a>
                                                <a href="https://www.instagram.com/sandalmist_resort_and_spa/" className="social-icon"><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </section>
                                        <section className="widget widget_categories mb-45">
                                            <h2 className="widget-title" style={{ fontSize: '1.5rem', fontWeight: '700' }}>Quick Access</h2>
                                            <ul>
                                                <li><Link to="/about" style={{ color: '#85ab00' }}>About</Link></li>
                                                <li><Link to="/gallery" style={{ color: '#85ab00' }}>Gallery</Link></li>
                                                <li><Link to="/testimonial" style={{ color: '#85ab00' }}>Testimonial</Link></li>
                                                <li><Link to="/attraction" style={{ color: '#85ab00' }}>Attraction Nearby</Link></li>
                                            </ul>
                                        </section>
                                        <section className="widget widget_recent_entries">
                                            <h2 className="widget-title" style={{ fontSize: '1.5rem', fontWeight: '700' }}>Recent News</h2>
                                            {blogsList.slice(0, 4).map((blog) => (
                                                <div className="recent-post-wrap" key={blog._id}>
                                                    <div className="post-thumb">
                                                        <Link to={`/blog/${blog._id}`}><img src={blog.blogImages[0]} alt="" style={{ width: '100%', height: 'auto' }} /></Link>
                                                    </div>
                                                    <div className="post-content">
                                                        <h3><Link to={`/blog/${blog._id}`} style={{ color: '#85ab00' }}>{blog.title}</Link></h3>
                                                        <div className="meta-info">
                                                            <ul>
                                                                <li style={{ fontSize: '0.9rem', color: '#6c757d' }}><i className="fal fa-calendar-alt"></i> {monthDateYear(blog.createdAt)}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </section>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="contact-form-section pt-120 pb-120" style={formStyle}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="contact-form-wrap" style={formWrapStyle}>
                                        <h3 style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Contact Us</h3>
                                        <form onSubmit={submitContact}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} required style={textareaStyle}></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-primary" style={btnStyle}>Send Message</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </main>
            </>
        );
    } else {
        return <div><Loader/></div>;
    }
}

export default BlogDetails;
