import axios from "axios";
import config from "../../services/config";

const GetBlogs ={
    loadBlogs(dispatch){
        dispatch({type:'LOAD_BLOGS', payload:null})
        axios.get(config.baseUrl+"getAllBlogs").then((blogs) => { dispatch({type:"GET_BLOGS",  payload:blogs})}).catch(()=>{
            dispatch({type:"ERROR_BLOGS",payload:null})
        }).finally(()=>{})
    },
}

export default GetBlogs;