import { Link } from 'react-router-dom';
import BGImg from "../../../assets/img/bg/bdrc-bg.jpg";
import React, { useState, useEffect } from 'react';
import FsLightbox from 'fslightbox-react';
import axios from 'axios';
import config from '../../../services/config';
import { Gallery } from "react-grid-gallery";
import Blog from "../../../assets/img/bg/an-img-06.png";
import galleryBanner from "../../../assets/img/gallery/galleryBanner.jpg";

const Main = () => {
    const [activeImage, setActiveImage] = useState(1);
    const [blogsList, setBlogsList] = useState([]);
    const [GalleryList, setGalleryList] = useState([]);
    const [toggler, setToggler] = useState(false);
    const [tabMenu, tabActive] = useState({ all: true });
    const [images, setImages] = useState([]);  // Added this line

    useEffect(() => {
        getAllGalleryImageAPI();
        getRecentBlogsAPI();
    }, []);

    function getRecentBlogsAPI() {
        axios({
            url: config.baseUrl + "getRecentBlogs",
            method: "GET",
            headers: {},
        }).then((res) => {
            if (res.status === 200) {
                setBlogsList(res.data.data);
            } else if (res.status === 201) {
                console.log(res.data.message);
            }
        }).catch((err) => { console.log(err.toString()) });
    }

    function monthDateYear(dates) {
        const date = new Date(dates);
        const monthName = date.toString().split(' ')[2] + ", " + date.toString().split(' ')[1] + " " + date.toString().split(' ')[3];
        return monthName;
    }

    function getAllGalleryImageAPI() {
        axios({
            url: config.baseUrl + "getAllGalleryImages",
            method: "GET",
            headers: {},
        }).then((res) => {
            if (res.status === 200) {
                setGalleryList(res.data.data.filter(e => e.isActivityImage === true));
            } else if (res.status === 201) {
                console.log(res.data);
            }
        }).catch((err) => { console.log(err.toString()) });
    }

    const ImageDataAll = GalleryList.map(img => ({
        src: img.galleryImage,
        thumbnail: img.galleryImage,
        thumbnailWidth: 480,
        thumbnailHeight: 300,
        id: img.activityID,
        activity: img.activityTitle,
        tags: [
            { value: img.activityTitle ?? '', title: img.activityTitle ?? '' },
        ],
    }));

    const tabNamesList = [...new Set(ImageDataAll.map(tab => tab.activity))];

    const filteredImageData = GalleryList.filter(fil => fil.activityTitle === Object.keys(tabMenu)[0]).map(img => ({
        src: img.galleryImage,
        thumbnail: img.galleryImage,
        thumbnailWidth: 480,
        thumbnailHeight: 300,
        id: img.activityID,
        activity: img.activityTitle,
        tags: [
            { value: img.activityTitle ?? '', title: img.activityTitle ?? '' },
        ],
    }));

    const imagesFltr = filteredImageData.map(img => img.src);

    const galleryHeight = () => {
        if (tabMenu.all) {
            return "h1200";
        } else if (tabMenu.financial) {
            return "h600";
        } else {
            return "h300";
        }
    };

    return (
        <>
            <main>
                <section className="breadcrumb-area d-flex align-items-center " style={{ background: `url(${galleryBanner})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-center">
                                    <div className="breadcrumb-title">
                                        <h2 style={{ textShadow: '2px 2px 0px  #85ab00, -2px -2px 0px  #85ab00, 2px -2px 0px  #85ab00, -2px 2px 0px  #85ab00' }} >Gallery</h2>
                                        <div className="breadcrumb-wrap">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Galler </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="profile fix pt-120 pb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="my-masonry text-center mb-50">
                                    <div className="button-group filter-button-group ">
                                        <button className={`${tabMenu.all && "active"}`} onClick={() => tabActive({ all: true })} data-filter="*">All</button>
                                        {tabNamesList.map(btnTab => (
                                            <button key={btnTab} className={`${tabMenu[btnTab] && "active"}`} onClick={(e) => { tabActive({ [btnTab]: true }); e.stopPropagation(); e.preventDefault(); }} data-filter={btnTab}>{btnTab}</button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="masonry-gallery-huge">
                                    <div className={`grid col2 ${galleryHeight()}`} style={{ position: "relative", height: "auto" }}>
                                        {Object.values(tabMenu)[0] && (
                                            <div className="place_details_component">
                                                <div className="container">
                                                    <div className="row">
                                                        <Gallery onClick={() => {
                                                            setImages(imagesFltr);  // Now properly defined
                                                            setToggler(!toggler);
                                                        }} images={filteredImageData} enableImageSelection={false} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {tabMenu.all && (
                                            <div className="place_details_component">
                                                <div className="container">
                                                    <div className="row">
                                                        <Link to="#" onClick={() => setToggler(true)} className="popup-image">
                                                            <Gallery images={ImageDataAll} enableImageSelection={false} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <FsLightbox
                toggler={toggler}
                sources={imagesFltr}
                slide={activeImage}
                types={new Array(imagesFltr.length).fill('image')}
            />
        </>
    );
}

export default Main;
