const initialState={
    blogsList:[],
    loading:false,
    error:false
}

function BlogsReducer(state = initialState, action){
    switch(action.type){
        case'LOAD_BLOGS':
            return {...state, blogsList:[], error:false, loading:true };
        case 'GET_BLOGS':

        return {...state, blogsList:action.payload, error:false, loading:false}
        case 'ERROR_BLOGS':
            return {...state, blogsList:[], error:true, loading:false}

    default:
            return state;
    }
}

export default BlogsReducer;