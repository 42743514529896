const initialState={
    activitiesList:[],
    loading:false,
    error:false
}

function ActivitiesReducer(state = initialState, action){
    switch(action.type){
        case'LOAD_ACTIVITIES':
            return {...state, activitiesList:[], error:false, loading:true };
        case 'GET_ACTIVITIES':

        return {...state, activitiesList:action.payload, error:false, loading:false}
        case 'ERROR_ACTIVITIES':
            return {...state, activitiesList:[], error:true, loading:false}

    default:
            return state;
    }
}

export default ActivitiesReducer;