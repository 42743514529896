import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import activitiesList from './reducers/ActivitiesReducer';
import cottagesList from './reducers/CottageReducer';
import blogsList from './reducers/BlogsReducer';

// Middleware setup
const middleware = applyMiddleware(thunk);

// Enable Redux DevTools Extension in the browser
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Combine reducers into a single root reducer
const reducers = combineReducers({
    activitiesData: activitiesList,
    cottagesData: cottagesList,
    blogsData: blogsList
});

// Create the Redux store with reducers and middleware
export const store = createStore(reducers, composeEnhancers(middleware));
