import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../services/config";
import Loader from "../../loader";
import { Link } from "react-router-dom";

const FacilityDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [facility, setFacility] = useState(null);
  const [blogsList, setBlogsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getFacilities();
        await getRecentBlogsAPI();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [id]); // Adding id as a dependency

  const getFacilities = async () => {
    try {
      const res = await axios.get(config.baseUrl + "getAllFacilities");
      if (res.status === 200) {
        const facilities = res.data.data;
        const exactFacility = facilities.find(
          (facility) => facility._id === id
        );
        setFacility(exactFacility);
      } else {
        console.log("Failed to fetch facilities:", res.data.message);
      }
    } catch (err) {
      console.log("Error fetching facilities:", err.toString());
    }
  };

  const getRecentBlogsAPI = async () => {
    try {
      const res = await axios.get(config.baseUrl + "getRecentBlogs");
      if (res.status === 200) {
        setBlogsList(res.data.data);
      } else {
        console.log("Failed to fetch recent blogs:", res.data.message);
      }
    } catch (err) {
      console.log("Error fetching recent blogs:", err.toString());
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!facility) {
    return <p>Facility not found</p>;
  }

  return (
    <main>
      <section
        className="breadcrumb-area d-flex align-items-center"
        style={{
          background: `url(${
            facility.menuImage || "https://default-image-url"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          color: "#fff",
          padding: "40px 0",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12 text-center breadcrumb-wrap">
              <div
                className="breadcrumb-title"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h1
                  style={{
                    fontSize: "3rem",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "whitesmoke",
                    // textShadow: '2px 2px 0px #85ab00'
                  }}
                >
                  {facility.title}
                </h1>
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{ marginBottom: 0 }}>
                      <li className="breadcrumb-item">
                        <Link to="/" style={{ color: "#85ab00" }}>
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {facility.title} Details
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="about-area5 about-p p-relative">
        <div className="container pt-120 pb-90">
          <div className="row" style={{ display: "flex" }}>
            <div
              className="col-lg-8 col-md-12 col-sm-12"
              style={{ paddingRight: "15px" }}
            >
              <div className="service-detail">
                <div
                  className="content-box"
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <h2 style={{ color: "#333", marginBottom: "15px" }}>
                    {facility.title}
                  </h2>
                  <p style={{ color: "#555", lineHeight: "1.6" }}>
                    {facility.description}
                  </p>

                  <div className="two-column">
                    <div className="row">
                      {facility.menuImages &&
                        facility.menuImages.map((image, index) => (
                          <div
                            className="text-column col-xl-6 col-lg-12 col-md-12"
                            key={index}
                          >
                            <figure className="image">
                              <img
                                src={image}
                                alt={`Facility Image ${index + 1}`}
                                style={{
                                  border: "none",
                                  borderRadius: "8px",
                                  maxWidth: "100%",
                                  height: "auto",
                                }}
                              />
                            </figure>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="two-column">
                    <div className="row">
                      <div className="image-column col-xl-12 col-lg-12 col-md-12">
                        <figure className="image">
                          <img
                            src={facility.menuImage}
                            alt="Facility Details"
                            style={{
                              border: "none",
                              borderRadius: "8px",
                              maxWidth: "100%",
                              height: "auto",
                            }}
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-12 col-sm-12"
              style={{ paddingLeft: "15px" }}
            >
              <aside
                className="sidebar"
                style={{
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* <div className="sidebar-widget" style={{ marginBottom: '20px' }}>
                                    <h2 className="widget-title" style={{ fontSize: '1.5rem', marginBottom: '15px' }}>Recent Posts</h2>
                                    <ul className="services-categories" style={{ listStyleType: 'none', padding: 0 }}>
                                        {blogsList.map((blog) => (
                                            <li key={blog._id} style={{ marginBottom: '10px' }}>
                                                <Link to={`/blog-details/${blog._id}`} style={{ textDecoration: 'none', color: '#333', fontSize: '1rem' }}>
                                                    {blog.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                <div
                  className="service-detail-contact"
                  style={{ marginTop: "20px" }}
                >
                  <h3
                    className="h3-title"
                    style={{ fontSize: "1.5rem", marginBottom: "15px" }}
                  >
                    If You Need Any Help Contact Us
                  </h3>
                  <Link
                    to="/contact"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontSize: "1rem",
                      padding:"1rem 1rem",
                      backgroundColor: "#85ab00",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "#749e00",
                        color: "#fff",
                      },
                      

                    }}
                  >
                    Contact us now
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FacilityDetails;
