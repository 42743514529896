import React from 'react';

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div className="loading">
          {Array.from({ length: 16 }).map((_, index) => (
            <div key={index} />
          ))}
        </div>
      </div>
      <style>{`
        .loader-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
        }

        .loader-container {
          position: relative;
          width: 60px;
          height: 60px;
        }

        .loading {
          width: 60px;
          height: 60px;
          transform: rotate(45deg);
        }

        .loading div {
          width: 6px;
          height: 6px;
          background: white;
          border-radius: 100%;
          float: left;
          margin-bottom: 12px;
          animation: scaleDot 2s ease infinite;
        }

        .loading div:not(:nth-child(4n+4)) {
          margin-right: 12px;
        }

        /* Row 1 */
        .loading div:nth-child(1) {
          animation-delay: 0;
        }
        /* Row 2 */
        .loading div:nth-child(2),
        .loading div:nth-child(5) {
          animation-delay: 0.1s;
        }
        /* Row 3 */
        .loading div:nth-child(3),
        .loading div:nth-child(6),
        .loading div:nth-child(9) {
          animation-delay: 0.2s;
        }
        /* Row 4 */
        .loading div:nth-child(4),
        .loading div:nth-child(7),
        .loading div:nth-child(10),
        .loading div:nth-child(13) {
          animation-delay: 0.3s;
        }
        /* Row 5 */
        .loading div:nth-child(8),
        .loading div:nth-child(11),
        .loading div:nth-child(14) {
          animation-delay: 0.4s;
        }
        /* Row 6 */
        .loading div:nth-child(12),
        .loading div:nth-child(15) {
          animation-delay: 0.5s;
        }
        /* Row 7 */
        .loading div:nth-child(16) {
          animation-delay: 0.6s;
        }

        @keyframes scaleDot {
          40% {
            transform: scale(1.3) translate(-2px, -2px);
          }
          80% {
            transform: scale(1);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;
