import axios from "axios";
import config from "../../services/config";

const cottageActions ={
    loadCottages(dispatch){
        dispatch({type:'LOAD_COTTAGES', payload:null})
        axios.get(config.baseUrl+"getAllCottages").then((cottages) => { dispatch({type:"GET_COTTAGES",  payload:cottages})}).catch(()=>{
            dispatch({type:"ERROR_COTTAGES",payload:null})
        }).finally(()=>{})
    },
}

export default cottageActions;