import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';
import axios from 'axios';
import config from '../../../services/config';
import { Gallery } from 'react-grid-gallery';
import galleryBanner from "../../../assets/img/gallery/pexels-Nature.jpg";

const Main = () => {
    const [toggler, setToggler] = useState(false);
    const [images, setImages] = useState([]);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [galleryList, setGalleryList] = useState([]);
    const [tabMenu, setTabMenu] = useState('all');

    useEffect(() => {
        getAllGalleryImageAPI();
    }, []);

    useEffect(() => {
        // Update images based on tab menu change
        updateGalleryImages();
    }, [tabMenu, galleryList]);

    useEffect(() => {
        // Ensure lightbox index update and toggling work as expected
        if (toggler) {
            setToggler(false); // Close the lightbox after setting the index
        }
    }, [lightboxIndex]);

    const getAllGalleryImageAPI = () => {
        axios.get(`${config.baseUrl}getAllGalleryImages`)
            .then((res) => {
                if (res.status === 200) {
                    setGalleryList(res.data.data.filter(e => e.isActivityImage === true));
                }
            })
            .catch((err) => console.log(err));
    };

    const updateGalleryImages = () => {
        const filteredImages = galleryList
            .filter(img => tabMenu === 'all' || img.activityTitle === tabMenu)
            .map(img => ({
                src: img.galleryImage,
                thumbnail: img.galleryImage,
                thumbnailWidth: 480,
                thumbnailHeight: 300,
                id: img.activityID,
                activity: img.activityTitle,
                tags: [{ value: img.activityTitle ?? '', title: img.activityTitle ?? '' }],
            }));

        setImages(filteredImages);
    };

    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setToggler(true);  // Ensure the lightbox is toggled to show the image
    };

    return (
        <>
            <main>
                <section className="breadcrumb-area d-flex align-items-center" style={{ background: `url(${galleryBanner})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-center">
                                    <div className="breadcrumb-title">
                                        <h2>Gallery</h2>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile fix pt-120 pb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="my-masonry text-center mb-50">
                                    <div className="button-group filter-button-group">
                                        <button className={tabMenu === 'all' ? "active" : ""} onClick={() => setTabMenu('all')}>All</button>
                                        {Array.from(new Set(galleryList.map(img => img.activityTitle))).map(btnTab => (
                                            <button key={btnTab} className={tabMenu === btnTab ? "active" : ""} onClick={() => setTabMenu(btnTab)}>{btnTab}</button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="masonry-gallery-huge">
                                    <div className={`grid col2 ${tabMenu === 'all' ? 'h1200' : 'h300'}`} style={{ position: "relative", height: "auto" }}>
                                        <div className="place_details_component">
                                            <div className="container ">
                                                <div className="row ">
                                                    <Gallery
                                                        images={images}
                                                        enableImageSelection={false}
                                                        thumbnailImageComponent={({ imageProps, index }) => (
                                                            <img 
                                                                {...imageProps} 
                                                                style={{ width: '100%', height: '200px', objectFit: 'cover' }} 
                                                                onClick={() => handleImageClick(index)}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            
        </>
    );
};

export default Main;
