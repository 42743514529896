// src/components/Routing.jsx
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Navbar from '../component/Navbar/Navbar';
import Footer from '../component/Footer/Footer';
import Home from '../component/Home/Main';
import Home2 from '../component/Home2/Home2';
import Home3 from '../component/Home3/Home3';
import Home4 from '../component/Home4/Home4';
import About from '../component/About/About';
import OurRoom from '../component/Rooms/OurRoom/Main';
import RoomDetails from '../component/Rooms/RoomDetails/CottageDetails';
import ActivityDetails from '../component/Facilities/ActivityDetails/ActivityDetails';
import Gallery from '../component/Pages/Gallary/Gallery';
import Faq from '../component/Faq/Faq';
import Team from '../component/Pages/Team/Main';
import TeamDetails from '../component/Pages/TeamDetails/Main';
import Pricing from '../component/Pages/Pricing/Main';
import Shop from '../component/Pages/Shop/Main';
import ShopDetails from '../component/Pages/ShopDetails/Main';
import Blog from '../component/Blog/BlogMain/Main';
import BlogDetails from '../component/Blog/BlogDetails/Blog-Details';
import Contact from '../component/Contact/Contact';
import Attraction from '../component/Attraction_nearby/Attraction';
import TermsAndConditions from '../component/Terms_and_conditions/termsandconditions';
import Facilities from '../component/Pages/facilities/facilities';
import Testimonial from '../component/Pages/testimonials/TestimonialsPage';
import VideoGallery from '../component/Pages/Gallary/video-gallery';
import FacilityDetails from '../component/Pages/facilities/facilitydetails';
import ScrollToTop from '../component/ScrollToTop'; // Import ScrollToTop

const Routing = () => {
  const [homepage, setHomepage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/home-03' || location.pathname === '/home-04') {
      setHomepage(false);
    } else {
      setHomepage(true);
    }
  }, [location]);

  const [footerpage, setFooterPage] = useState(false);
  useEffect(() => {
    if (location.pathname === '/home-03') {
      setFooterPage(false);
    } else {
      setFooterPage(true);
    }
  }, [location]);

  return (
    <>
      {homepage && <Navbar />}
      <ScrollToTop /> {/* This will scroll to top on route change */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-02" element={<Home2 />} />
        <Route path="/home-03" element={<Home3 />} />
        <Route path="/home-04" element={<Home4 />} />
        <Route path="/about" element={<About />} />
        <Route path="/room" element={<OurRoom />} />
        <Route path="/room-details/:id" element={<RoomDetails />} />
        <Route path="/activity-details/:id" element={<ActivityDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/team" element={<Team />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop-details" element={<ShopDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/attraction" element={<Attraction />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/facilities/:id" element={<Facilities />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/video-gallery" element={<VideoGallery />} />
        <Route path="/facility-details/:id" element={<FacilityDetails />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
