import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../services/config";
import avatar1 from "../../../assets/img/logo/logo.png";
import { Link } from "react-router-dom";
import BGImg from "../../../assets/img/bg/testimonial-ban.jpg";
const Main = () => {
  const [testimonialsList, setTestimonialsList] = useState([]);

  useEffect(() => {
    getAllTestimonialAPI();
  }, []);

  function getAllTestimonialAPI() {
    axios({
      url: config.baseUrl + "getAllTestimonials",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.status === 200) {
          setTestimonialsList(res.data.data);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.toString());
      });
  }

  return (
    <>
    <section
  className="breadcrumb-area d-flex align-items-center"
  style={{
    background: `url(${BGImg}) no-repeat center center/cover`,
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2
              style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                textShadow:
                  "",
              }}
            >
              Testimonials
            </h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    id="top"
                    className="breadcrumb-item active "
                    aria-current="page"
                  >
                    Testimonials
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <section className="testimonial-area py-5 " >
       
      <div className="container">
        <div className="row text-center mb-5">
          <div className="col-12">
            <h2 className="display-6 mb-3 ">What Our Clients Say</h2>
            <p className="lead text-muted justify-content-center text-center">
              Hear from our satisfied clients about their experiences with our services.
            </p>
          </div>
        </div>
        <div className="row">
          {testimonialsList.map((testimony, key) => (
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4" key={key}>
              <div
                className="testimonial-item p-4 rounded shadow-sm"
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="d-flex align-items-center mb-4">
                  <img
                    src={testimony.avatar ? testimony.avatar : avatar1}
                    className="rounded-circle me-3"
                    width={80}
                    height={80}
                    alt="author"
                    style={{
                      border: "3px solid #007bff",
                    }}
                  />
                  <div>
                    <h5 className="mb-1" style={{ fontWeight: "700", fontSize: "1.2rem" }}>{testimony.name}</h5>
                    <span className="text-muted">Client</span>
                  </div>
                </div>
                <p className="mb-0 text-muted" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
                  {testimony.testimony.length > 200
                    ? `${testimony.testimony.substring(0, 150)}...`
                    : testimony.testimony}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section></>
  );
};

export default Main;
