import React from "react";
import { Link } from "react-router-dom";
import BGImg from "../../assets/img/bg/footerban2.jpg";

const Main = () => {
  return (
    <>
      <footer className="footer-bg footer-p">
        <div
          className="footer-top  pt-90 pb-40"
          style={{
            background_color: "#85ab00",
            backgroundImage: `url(${BGImg})`,
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-30">
                    <img
                      src="../../assets/img/logo/SandalMist_logo.svg"
                      alt="img"
                      style={{
                        filter: "brightness(1.5)", // Increase brightness and contrast
                        maxWidth: "170px", // Ensure the image does not stretch beyond its original size
                        height: "auto", // Maintain aspect ratio
                        imageRendering: "auto", // Use browser’s default image rendering algorithm
                      }}
                    />
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal fa-phone"></i>
                        <span>
                          +91-9778434442
                          
                          <br />
                          +91-9778474442
                        </span>
                      </li>
                      <li >
                        <i className="icon fal fa-envelope "></i>
                        <span>
                          <Link to="#">Info@sandalmistresort.com</Link>
                          <br />
                          <Link to="#">feedback@sandalmistresort.com</Link>
                          <br />
                          
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check"></i>
                        <span>
                        SandalMist Resort & SPA
                          <br /> Munnad, Kasargod, Kerala
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Quick Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about"> About Us</Link>
                      </li>
                      {/* <li><Link to="/services"> Services </Link></li> */}
                      <li>
                        <Link to="/contact#head"> Contact Us</Link>
                      </li>
                      {/* <li><Link to="/blog">Blog </Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Other Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      {/* <li><Link to="/faq">FAQ</Link></li> */}
                      {/* <li><Link to="#">Support</Link></li> */}
                      {/* <li><Link to="#">Privacy</Link></li> */}
                      <li>
                        <Link to="/terms">Terms & Conditions</Link>
                      </li>
                      {/* <li><Link to="#">Terms & Conditions</Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                  <h2>Subscribe To Our Newsletter</h2>
                                </div>
                                <div className="footer-link">
                                <p>Subscribe our newsletter to get our latest update &amp; News</p>
                                    <div className="subricbe p-relative" data-animation="fadeInDown" data-delay=".4s" >
                                        <form action="news-mail.php" method="post" className="contact-form ">
                                            <input type="text" id="email2" name="email2"  className="header-input" placeholder="Your Email..." required />
                                            <button className="btn header-btn"> <i className="fas fa-location-arrow"></i> </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                Copyright &copy; Sandalmist 2022 . All rights reserved.
              </div>
              <div className="col-lg-6 col-md-6 text-right text-xl-right">
              <div className="footer-social">
  <a href="https://www.facebook.com/sandalmistresortSandalMistresortsandspa/" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-facebook-f"></i>
  </a>
  <a href="https://x.com/sandalmist" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-twitter"></i>
  </a>
  <a href="https://www.instagram.com/sandalmist_resort_and_spa/" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-instagram"></i>
  </a>
  <a href="https://www.youtube.com/@SandalmistResortsandSpa" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-youtube"></i>
  </a>
</div>

              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Main;
